.driver-costs
  margin-bottom: 40px

.drivers-costs-header
  height: 50px
  background-color: var(--main-col-op)
  display: flex
  align-items: center
  color: var(--white)
  font-weight: 600
  font-size: 14px
  padding: 10px 20px
  justify-content: space-between

  &__title
    font-size: 16px

  &--left
    display: flex
    align-items: center

  &--right
    display: flex
    gap: 20px

  &__cell
    border-right: 1px solid rgba(224, 224, 224, 1)

  &__buttons
    margin-left: 20px

  &__select
    z-index: 99

  &__button
    background: var(--white)
    border-radius: 4px
    font-size: 14px
    color: var(--sidebar-bg-col)
    font-weight: 500
    border: none
    padding: 6px 12px
    cursor: pointer

  &__filter
    display: flex
    align-items: center
    gap: 10px
    min-width: 200px


.driver-costs-table

  &__header
    background-color: var(--topbar-col)
    z-index: 0

  &__cell
    font-weight: 600
    color: var(--btn-dark)

  &__route
    display: flex
    gap: 10px
    align-items: baseline

  &__options
    display: flex
    gap: 5px
    align-items: baseline
    z-index: 0
    justify-content: flex-end
    margin-right: 5px

  &__pagination
    width: calc(100vw - 200px)
    height: 42px
    background-color: var(--main-col-op)
    display: flex
    align-items: center
    justify-content: space-between
    padding-left: 20px
    padding-right: 20px
    font-size: 16px
    box-sizing: border-box
    position: fixed
    bottom: 0

  &__value-wrapper
    display: flex
    gap: 2px
    align-items: baseline

  &__input-wrapper
    display: flex
    gap: 8px
    align-items: center

  &__input
    border: 1px solid var(--input-bor)
    border-radius: 5px
    padding: 8px
    font-size: var(--fontSize)
    color: var(--text-col)
    background-color: var(--input-bg)
    box-sizing: border-box
    transition: all .3s ease
    height: 38px
    max-width: 70px

  &__addition-wrapper
    display: flex
    justify-content: space-between

  &__addition-labels
    display: flex
    font-size: 12px
    justify-content: space-between

  &__row-wrapper
    display: flex
    align-items: center
    gap: 10px
    max-width: 170px

  &__value-currency
    font-size: 12px

  &__button-edit
    opacity: 0.7

    &:hover
      opacity: 1


.react-datepicker-popper
  z-index: 9999

.MuiTableRow-root
  height: 75px
