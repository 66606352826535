.loginPage {
	background-image: var(--login_bg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.loginLink, 
.loginLoginLink,
.loginLoginLink button {
	color: var(--white);
	font-size: var(--fontSize);
	margin: 20px;
	align-self: flex-end;
	text-shadow: #000 0 1px 1px;
}

.mr {
	margin-right: 40px;
}

.loginLink a, 
.loginLoginLink a,
.loginLoginLink button {
	color: var(--white);
	transition: color 0.2s ease;
	text-decoration: none;
}

.loginLink a:hover, 
.loginLoginLink a:hover,
.loginLoginLink button:hover {
	color: var(--text-col)
}

.login {
	top: 188px;
	left: 720px;
	width: 480px;
	height: auto;
	background: '#FFFFFF00 0% 0% no-repeat padding-box';
	opacity: 1;
	backdrop-filter: blur(31px) brightness(0.8);
	padding: 50px 80px;
	box-sizing: border-box;
	color: var(--white)
}

.login h2 {
	font-weight: 600;
	font-size: 30px;
	text-align: center;
	margin-top: 0
}

.login h5 {
	font-weight: 500;
	font-size: 19px;
	text-align: center;
	margin-top: 45px;
}

.input {
	color: var(--white);
}

.input input[type="number"] {
	-moz-appearance:textfield;
}

.input input[type="number"]::-webkit-outer-spin-button,
.input input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input label {
	color: var(--white);
}

.forgot {
	font-size: 0.8rem;
	width: 100%;
	text-align: center;
	cursor: pointer;
	text-decoration: underline rgba(255, 255, 255, 0);
	transition: text-decoration-color 300ms
}

.forgot:hover {
	text-decoration-color: rgba(255, 255, 255, 1);
}
.error {
	font-size: 0.8rem;
	width: 100%;
	color: var(--err-col);
	text-align: center;
	line-height: 20px;
}

.logBtn, button.logBtn {
	margin-top: 50px;
	margin-bottom: 20px;
	padding: 12px 22px;
	font-size: 13px;
	text-transform: capitalize;
	background-color: var(--main-col);
}
.logBtn:hover, 
button.logBtn:hover{
	background-color: var(--main-col-hover);
}
.box {
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
	/* height:400px; */
  background-color: var(--white);
  border: 2px solid rgb(114, 114, 114);
  box-shadow: 24px;
	border-radius: 10px;
	padding:20px;
	display:flex;
	flex-direction: column;
	align-items:center
}

.MuiInput-underline:before {
    border-bottom: 2px solid rgb(214, 47, 47) !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid rgb(214, 47, 47) !important;
}



/* promotion */

.promotion {
	top: 188px;
	left: 720px;
	width: 480px;
	height: auto;
	background: '#FFFFFF00 0% 0% no-repeat padding-box';
	opacity: 1;
	backdrop-filter: blur(31px) brightness(0.8);
	padding: 50px 80px;
	box-sizing: border-box;
	color: var(--white)
}

.promotion h2 {
	font-weight: 600;
	font-size: 30px;
	text-align: center;
	margin-top: 0
}

.promotion h5 {
	font-weight: 500;
	font-size: 15px;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 30px;
}
.promotion .input{
	margin-top: 10px;
}
.promotion .input label{
	font-size: 14px;
}

.loginLoginLink {
	position: absolute;
    top: 40px;
    left: 20px;
    margin: 0;
}
.loginLoginLink button{
	background-color: transparent;
	font-family: "Poppins";
	border: 0;
	margin: 0;
    cursor: pointer;
}
.load{
	display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
	padding-top: 15px;
}
.goToLogin h5{
	margin-bottom: 0;
}
.goToLogin button{
	text-transform: none;
}