.tabbar {
	height: 40px;
	flex-grow: 1;
	width: 100%;
	background-color: var(--bg);
	display: flex;
	z-index: 9;
}

button.tab {
	font-size: var(--fontSize);
	color: var(--text-col);
	padding: 11px 20px;
	font-weight: 400;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	height: 40px;
	min-height: 40px;
}

button.tab span {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	height: 40px;
	min-height: 40px;
}

.tab.active {
	color: var(--main-col)
}

.tab svg {
	margin-left: 10px;
	stroke: var(--light)
}

.tab::after {
	content: " ";
	width: 1px;
	height: 26px;
	background-color: var(--light);
	position: absolute;
	right: 0px;
	top: 7px
}

.icon {
	position: absolute;
}

.tab span:first-child {
	max-height: 40px;
	padding-top: 9px;
}