/* .topbar {
	height: 40px;
	width: 100%;
	background-color: var(--topbar-col);
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	z-index: 9;
	position: relative;
	box-shadow: inset 0 -1px 13px -10px #000;
} */

/* .topbar {
	height: 40px;
	width: 100%;
	background-color: var(--topbar-col);
	z-index: 9;
	position: relative;
	box-shadow: inset 0 -1px 13px -10px #000;
	display: flex;
	grid-template-columns: calc(100% - 381px) 117px 66px 198px;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: ". a_email topbar_a a_optionBar";
} */

.topbar {
	height: 40px;
	width: 100%;
	background-color: var(--topbar-col);
	z-index: 9;
	position: relative;
	box-shadow: inset 0 -1px 13px -10px #000;
	display: grid;
	grid-template-columns: 1fr auto 66px 198px;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: ". a_email topbar_a a_optionBar";
}

.topbar a {
	grid-area: topbar_a;
}

.email {
	color: var(--headtxt-col);
	height: 100%;
	line-height: 40px;
	font-size: var(--fontSize);
	font-weight: 300;
	grid-area: a_email;
}

.userIco {
	width: 16px;
	height: 16px;
	stroke: var(--headtxt-col);
	margin: 11px 25px;
}

.optionBar {
	display: flex;
	height: 100%;
	background-color: var(--main-col);
	justify-content: flex-start;
	align-items: flex-start;
	grid-area: a_optionBar;
}

.ico {
	width: 16px;
	height: 16px;
	stroke: var(--white);
	margin: 11px 25px;
	color: var(--white);
}

.linkIco::after {
	background-color: var(--white);
	content: " ";
	height: 24px;
	width: 1px;
	position: absolute;
	top: 7px;
	right: 0px;
	transition: all 0.3 ease;
}

.linkIco {
	position: relative;
	cursor: pointer;
}

.linkIco:hover svg {
	transform: rotateX(180)
}

.newsBar {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0;
	height: 40px;
}


.news_slider {
	overflow: hidden;
	width: 100%;
	/* max-width: 1300px; */
	border-right: 1px solid #c3c3c3;
	margin-right: 20px;
	position: relative;
}

.news_slider_content {
	display: flex;
	transition: transform 1s ease-in-out;
	max-height: 40px;
}

.news_item {
	display: flex;
	flex-shrink: 0;
	width: 100%;
	height: 40px;
	gap: 8px;
	padding: 0 20px;
}

.news_empty {
	display: flex;
	font-size: 11px;
	align-items: center;
	gap: 5px;
	opacity: .4;
	user-select: none;
	padding: 0 20px;
	height: 40px;
}

.news_empty svg {
	width: 23px;
}

.news_navi {
	padding: 0 10px;
	position: absolute;
	z-index: 99;
	width: 145px;
	display: flex;
	right: -1px;
	grid-gap: 5px;
	gap: 5px;
	justify-content: flex-end;
	font-size: 12px;
	height: 40px;
	align-items: center;
	min-width: 145px;
	background: var(--bg_light);
	box-shadow: inset 0 -12px 11px -20px #000;
}

.news_navi button {
	background-color: var(--newsDefaultBtnBg);
	color: var(--white);
	border: 0;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 100px;
	cursor: pointer;
}

.news_navi button:hover {
	background-color: var(--main-col-hover);
}

.counter {
	min-width: 35px;
	display: flex;
	justify-content: center;
	color: var(--newsColor)
}

.news_navi button svg {
	width: 16px;
}

.news_item h2 {
	display: flex;
	font-size: 12px;
	margin-top: 0;
	font-weight: 700;
	line-height: .9;
}

.news_item p {
	margin-bottom: 0;
	font-size: 11px;
}

.news_item button {
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	border: 0;
	color: var(--white);
	border-radius: 3px;
	cursor: pointer;
}

.news_item button:hover {
	opacity: 0.8;
	transition: opacity .2s ease;
}

.news_item button.check {
	background-color: var(--bg_green);
}

.history24 {
	font-size: 10px;
	font-weight: 700;
	background-color: var(--cor-col) !important;
}

.news_item button.more {
	background-color: var(--main-to-op);
}

.news_item a {
	display: block;
	margin-top: 10px;
}

.news_btns {
	display: flex;
	padding: 8px 0;
	gap: 5px
}

.news_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 1px;
	color: var(--newsColor)
}

.flex_label {
	display: flex;
	align-items: center;
	gap: 6px;
}

.flex_label svg {
	max-width: 18px;
}

.komunikator_tab_label,
.unloading_tab_label,
.loading_tab_label,
.readed_tab_label,
.noreaded_tab_label {
	padding: 2px 7px;
	border-radius: 3px;
	font-size: 11px;
}

.komunikator_tab_label {
	background: rgba(247, 223, 9, 0.3);
}

.unloading_tab_label {
	background: rgba(88, 139, 221, 0.15);
}

.loading_tab_label {
	background: rgba(18, 152, 101, 0.15);
}

.readed_tab_label {
	background: var(--cor-col);
	color: var(--white);
}

.noreaded_tab_label {
	background: var(--main-col);
	color: var(--white);
}

.circleIcon {
	background: rgb(247 223 9 / 26%);
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
}

.circleIcon svg {
	background-color: red;
	color: red;
}

.btns {
	display: flex;
	justify-content: flex-end;
}