.btnRow {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.endBtnRow {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-left: auto;
}

.l50 {
  margin-left: 50px;
}

button.btn {
  margin: 10px;
  color: var(--white);
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  transition: opacity 0.3s ease;
}

button.btnDrawer {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  color: var(--white);
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

div.titleBtn,
button.titleBtn {
  margin: 0 3px 1px;
  color: var(--white);
  text-transform: none;
  font-size: 10px;
  font-weight: 500;
  height: 25px;
  transition: all 0.3s ease;
}

div.titleBtn,
button.titleBtnActive {
  margin: 0 3px 1px;
  color: var(--white);
  text-transform: none;
  font-size: 10px;
  font-weight: 500;
  height: 25px;
  transition: all 0.3s ease;
  background-color: green;
}

div.titleBtn {
  background-color: var(--white);
  color: var(--sidebar-bg-col) !important;
  border: 0;
}

div.titleBtn span {
  font-size: 10px !important;
  font-weight: 500;
}

div.titleBtn:hover {
  background: rgba(255, 255, 255, 0.5) !important;
  color: var(--white) !important;
  border: 0 !important;
}

button.smallBtn {
  margin: 0 3px 1px;
  color: var(--white);
  text-transform: none;
  font-size: 10px;
  font-weight: 500;
  height: 25px;
  transition: all 0.3s ease;
  width: 30px;
  min-width: unset;
  padding: 7px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

button.smallBtn span svg {
  margin-left: 0;
}

button.activeBtn {
  background-color: var(--main-col);
  color: var(--btn-withe) !important;
}

button.btn:disabled {
  opacity: 0.5;
  color: var(--white);
  background-color: var(--main-col);
}

button.btnDrawer.err,
button.titleBtn.err,
button.btn.err {
  background-color: var(--err-col) !important;
  opacity: 1;
}

button.btnDrawer.err:disabled,
button.titleBtn.err:disabled,
button.btn.err:disabled {
  opacity: 0.5;
}

button.titleBtn.yel,
button.btn.yel {
  background-color: var(--yel);
  opacity: 1;
}

button.titleBtn.yel:disabled,
button.btn.yel:disabled {
  opacity: 0.5;
}

button.titleBtn.grn,
button.btn.grn {
  background-color: var(--cor-col);
  opacity: 1;
}

button.blue {
  background-color: var(--btn-dark);
  opacity: 1;
  color: var(--white);
}

button.blue:hover {
  background-color: var(--btn-dark-hover);
}

button.btnMin {
  font-size: 10px;
  position: relative;
  min-height: 38px;
}

button.btnMin svg {
  padding-left: 8px;
}

button.titleBtn.grn:disabled,
button.btn.grn:disabled {
  opacity: 0.5;
}

.downBtnIco {
  width: 18px;
  margin-left: 8px;
}

.filterBtnIco {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.errStroke {
  stroke: var(--err-col);
  stroke-width: 4px;
  transition: all 0.3s ease;
}

*:disabled > span > .errStroke {
  stroke: currentcolor;
}

.rotate svg {
  transform: rotate(180deg);
}

.titleIcoBtn span {
  color: var(--white);
}

.titleIcoBtn svg {
  transition: all 0.3s ease;
}

button.titleIcoBtn {
  margin-left: auto;
  margin-right: 20px;
}

.BTNdelete {
  color: var(--main-col) !important;
}

button svg.BTNadd {
  color: var(--cor-col) !important;
}

button[disabled] {
  background-color: rgb(204, 204, 204);
  opacity: 0.7;
}

.loader {
  width: 18px !important;
  height: 18px !important;
  margin-left: 8px;
}

.loaderBig {
  width: 50px !important;
  height: 50px !important;
  /* margin-left: 8px; */
}

.btnRow button[disabled] {
  color: #000;
}

.btnGreen {
  background-color: var(--cor-col) !important;
}

.btnGreenWithe {
  background-color: var(--cor-col) !important;
}

.btnGreenWithe span {
  color: var(--white);
}

.btnCircularProgres {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  margin-left: -10px;
}

.selectedColor {
  width: 20px;
  height: 20px;
  margin-right: 9px;
  border-radius: 3px;
  border: 1px solid var(--input-bor);
}

.BTNColorSelect {
  background-color: #fbfbfb !important;
  color: #717171 !important;
  min-height: 38px;
  font-size: 12px !important;
  justify-content: flex-start !important;
  border: 1px solid var(--input-bor) !important;
  text-transform: unset !important;
  font-weight: 400 !important;
}

.selectedColor_option {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid var(--input-bor);
}

.addTag {
  top: 15px;
  left: -5px;
  border: 1px solid rgb(170 170 170 / 14%) !important;
  width: 100%;
}

.BTNCleanColorBox {
  position: absolute;
  right: 10px;
  bottom: 3px;
}

.BTNCleanColor {
  border: 0;
  background: transparent;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
}

.BTNCleanColor svg {
  width: 16px;
  height: 16px;
}

.costCalculate {
  font-size: 11px !important;
  padding-left: 10px !important;
  min-height: 38px;
  width: 100%;
}

.costCalculate span:first-child {
  gap: 6px;
}

.BOXNoLabelPosition {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.BTNColor {
  min-height: 38px;
  font-family: "Poppins";
  background: rgb(21, 47, 66);
  color: var(--white);
  border-radius: 4px;
}

.Payment {
  background-color: var(--sidebar-bg-col);
  height: 17px;
  padding: 3px 0 !important;
}

.PaymentContent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  gap: 2px;
}

.PaymentIcon {
  height: 13px;
  width: 13px;
}

.checkIcon {
  color: green;
  height: 18px;
  width: 18px;
}

.slashIcon {
  color: red;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.IconPlusLabel {
  display: flex;
  background-color: var(--main-col-op) !important;
  color: var(--white) !important;
  font-size: 11px !important;
  min-width: 20px !important;
  max-height: 20px;
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 100px !important;
  cursor: pointer;
}

.IconPlusLabel * {
  cursor: pointer;
}

.IconPlusLabel svg {
  width: 14px;
  height: 14px;
}

.IconPlusLabel img {
  padding: 0 6px 0 3px;
  position: relative;
  top: -1px;
}

.IconPlusLabel.IconWithLabel {
  padding-left: 6px !important;
}

.checkboxContainer {
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.checkbox {
  color: white !important;
  background-color: white !important;
  opacity: 1 !important;
}