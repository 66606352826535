.mpContainer {
    width: 350px;
    background: var(--white);
    top: 44px;
    font-family: 'Open Sans', sans-serif !important;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
}

.mpHeader {
    width: 100%;
    height: 29px;
    color: var(--white);
    padding: 5px 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #404952;
}
.mpHeaderTitle {
    width: auto;
    float: left;
    font-size: 13px;
    position: relative;
    top: 0px;
}
.mpHeaderClose {
    float: right;
    cursor: pointer;
}

div.tabs {
    min-height: 32px;
}

.tabRoot{
    text-transform: none !important;
    min-height: 28px !important;
    font-size: 11px !important;
    height: 20px !important;
    color: #222 !important;
    min-width: 117px !important;
}
.tabLink.current span {
    background: var(--main-col);
    color: var(--white);
    padding: 5px 18px;
    border-radius: 100px;
}
.tabSelected{
    /* background-color: var(--main-col) !important; */
    color: var(--main-col) !important;
    padding: 5px 18px !important;
    /* border-radius: 100px !important; */
}
.tabLink {
    width: 33% !important;
    margin: 0 !important;
    padding: 10px 0 !important;
    float: left !important;
    text-align: center !important;
}

.dragParent{
    height: auto;
    width: 300px;
    border: 1px solid #000;
    position: relative;
}
.ulPoints{
   padding-left: 0px; 
}
.liPoints{
    list-style-type: none;
    display:flex;
    align-items:center;
    margin: 5px 0
}
.dragPoints{
    width: 20px;
    height: 20px;
    /* background-color: #CCC; */
    float: left;
    cursor: pointer;
    margin-right:5px;
}

.dragPoints .stroke {
    stroke:var(--main-col);
    width: 20px;
    height: 20px;
}

.dragPoints .fill {
    fill: var(--main-col);
    width: 20px;
    height: 20px;
}


.renderOption{
    font-size: 10px;
}
button.removePoints{
    min-width: 20px;
    padding: 2px 2px;
    margin: 0 2px
}

.pointTextField{
    
}
.pointTextField input{
    
}

.ctrlLabel span:nth-child(2){
    font-size: 10px;
    color:var(--text-col)
}

button.loadBtn {
    margin: 20px
}

.tab3 {
    color: var(--text-col);
    font-size: 14px;
}

.input > input {
	border: 1px solid var(--input-bor);
	border-radius: 5px;
	padding: 4px;
	font-size: var(--fontSize);
	color: var(--text-col);
	background-color: var(--input-bg);
    margin-left: 5px;
    margin-right: 5px;
    width:100%;
}

.inputRoot {
    width:75%
}
.input {
    width:90%;
}

button.addPoint {
    padding: 2px 10px;
    margin:5px 30px;
}

button.addPoint svg {
    width: 18px;
    height: 18px;
    margin-left:6px;
}

.mr10 {
    margin-right:10px;
}

.option {
    display:flex;
    align-items:center
}

.select {width:300px}

.select>div {
    display:flex;
    align-items:center;
    padding: 12px;
}

.timePicker {
    width:300px
}

.timePicker input{
    padding:12px;
    height:25px;
}

.labelCheckbox {
    display:flex;
    align-items:center
}
.selectHalf {
    width:140px;
}

.selectHalf>div {
    display:flex;
    align-items:center;
    padding: 12px
}

div.selectHalfParent {
    margin-top: 20px
}

div.selectHalfParentLeft {
    margin-top: 20px;
    margin-left: 20px;
}

.date {
 text-align: center;
 color: var(--text-col);
 font-size: var(--fontSize);
}

.addressBox{
    background-color: var(--light);
    padding: 10px;
    display:flex;
    align-items: center
}

.center{
    text-align: center
}

.timeBox ,
.distBox ,
.costBox {
    padding:15px;
    display:flex;
    align-items:center;
    justify-content: center;
    margin-bottom:20px;
}

.timeBox {
    background-color: var(--cor-col-bg)
}

.distBox {
    background-color: var(--main-col-op2)
}

.costBox {
    background-color: var(--err-col-bg)
}

.addressBox .fill {
    margin-right:10px;
    fill: var(--main-col);
    font-size: var(--fontSize);
}