.a {
	fill: none;
	stroke: var(--main-col);
	stroke-miterlimit: 10;
	stroke-width: 1.9px;
}

.parent{
	width:12px;
	height:12px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.b {
	fill:var(--main-col);
}

.c {
	fill:none;
	stroke:var(--main-col);
	stroke-linecap:round;
	stroke-linejoin:round;
	stroke-width:2px;
}
