.statusParent {
	display: flex;
	align-items: center;
	flex-direction: row;
	text-align: right;
	justify-content: flex-end;
	gap: 5px;
}

.statusParent svg {
	max-width: 20px;
}

.text {
	width: auto;
}

.green {
	stroke: var(--cor-col)
}

.yellow {
	stroke: var(--yel)
}

.red {
	stroke: var(--err-col)
}

.ico {
	width: 30px;
	height: 30px
}