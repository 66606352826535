input.uploadFiles[type="file"] {
    padding: 10px;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
.upload {
  position: relative;
  overflow: hidden;
  width: auto;
  padding: 8px 14px;
  text-align: center;
  display: inline-block;
}
.upload input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.upload label {
  color: var(--text-col);
  display: flex;
  align-items: center;
  gap: 5px;
}
.uploadBtnBox{
  display: flex;
  cursor: pointer;
}
.uploadBtnBoxIcon{
  display: flex;
  position: absolute;
  right: -15px;
  bottom: -15px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: var(--cor-col);
  justify-content: center;
  align-items: center;
}
.uploadBtnBoxIconClean button{
  display: flex;
  position: absolute;
  right: -15px;
  top: -15px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: var(--main-col);
  justify-content: center;
  align-items: center;
  border: 0;
  color: var(--white);
}

.uploadBtnBoxIcon:hover,
.uploadBtnBoxIconClean:hover{
  opacity:.7;
}
.uploadBtnBoxIcon > div,
.uploadBtnBoxIconClean > div{
  padding: 0;
}
.uploadBtnBoxIcon > div > label > svg,
.uploadBtnBoxIconClean > div > label > svg{
  width: 18px;
  color: white;
}