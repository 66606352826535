.react-datepicker-popper {
    z-index: 9999;
  }

  .high {
    height: 150px;
    padding: 20px 10px 30px 10px !important;
  }

  .datePickers {
    padding: 30px 10px 30px 10px !important;
  }

  .error {
    color: red
  }
  
  .stripes {
    background-image: repeating-linear-gradient(
      45deg,
      #606dbc,
      #606dbc 10px,
      #465298 10px,
      #465298 20px
    );
    border-radius: 5px;
  }