.routePlaner{
    /* position: absolute;
    top: 52px;
    left: 600px; */
    
    width: 290px;
    /* height: auto; */

    /* min-height: 40px; */
    /* padding-bottom: 44px; */
    
    background-color: var(--white);

    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 27%);
}

.routePlaner .ulPoints{
    padding-left: 0px; 
 }

 .routePlaner .liPoints{
     list-style-type: none;
     width: 290px;
     clear: both;
 }

 .routePlaner .dragPoints{
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: inline-block;
    margin-top: 6px;
    margin-left: 4px;
    float: left;
    color: #666;
}

.routePlaner .inputPoints{
    display: inline-block;
    margin-left: 4px;
    float: left;
}

.inputClass{
    width: 210px;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--light);
    padding: 7px;
    display: inline-block;
    font-size: var(--fontSize);
    
}

.routePlaner .deletePoints{
    width: 20px;
    height: 20px;
    /* background-color: var(--light); */
    cursor: pointer;
    display: inline-block;
    margin-top: 6px;
    margin-left: 4px;
    float: left;
    color: #666;
}

.routePlaner .deletePoints:hover, .routePlaner .dragPoints:hover, .routePlaner .closePoints:hover, .routePlaner .addNewPoint:hover{
    color: #000;
}

.summaryContainer{
    display: inline-block;
    /* float: left; */
}

.routePlaner .closePoints{
    width: 20px;
    height: 20px;
    /* background-color: var(--light); */
    cursor: pointer;
    display: inline-block;
    margin-top: 6px;
    margin-left: 0px;
    color: #666;
}

.routePlaner .addNewPoint {
    width: 26px;
    height: 20px;
    /* background-color: var(--light); */
    cursor: pointer;
    display: inline-block;
    margin-top: 6px;
    padding-left: 3px;
    padding-right: 3px;
    float: left;
    border-right: 1px solid var(--light);
    color: #666;
}

.routeSummary{
    font-size: 9px;
    display: inline-block;
    width: 224px;
    padding-left: 6px;
    float: left;
    margin-top: 10px;
}