.box {
  position: relative;
  display: flex;
  flex-direction: column;
}

input.input {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  padding: 11px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  box-sizing: border-box;
  transition: all 0.3s ease;
}

input.input.error {
  border-color: var(--err-col);
}

input.input.dense {
  height: 24px;
}

input.input:focus {
  border: 1px solid var(--main-col);
  outline-color: var(--main-col);
}

input.input:disabled {
  background-color: var(--input-dis);
}

.element {
  margin: 3px -10px 3px -10px;
  padding: 0 10px 0 10px;
}

.list .element:hover {
  background-color: var(--highlight);
  cursor: pointer;
}

.list {
  position: absolute;
  z-index: 2225;
  background-color: var(--bg);
  border: var(--input-bor) 1px solid;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  transform: translateY(55px);
  overflow-y: auto;
  width: 100%;
  font-size: 11px;
  max-height: 200px;
}
.list.dense {
  transform: translateY(45px);
}

.element:nth-child(2n) {
  background-color: var(--lgray2);
}
