.traceBtn>span:first-child,
.traceBtnGreen>span:first-child {
  background: var(--cor-col);
  color: var(--white);
}

.flexRow {
  display: flex;
  gap: 10px;
}

.flexRow>div {
  display: flex;
}

.flexRow>div input {
  width: 100%;
}