.inp {
	display:flex;
	flex-direction: column;
	color:var(--text-col);
	width: 100%;
}

.inp:nth-child(2) {
	width: 50%;
}

.title {
	border-left:1px solid white;
	margin-bottom:50px;
}

.inputBox {
	padding: 15px 30px;
	display:flex;
	flex-direction: column;
	align-items: flex-start;
}

.inputBox label {
	font-size:14px;
	margin-top:12px;
	margin-bottom: 2px;
}

.textarea {
	border: 1px solid var(--input-bor);
	border-radius: 5px;
	padding: 10px;
	font-size: var(--fontSize);
	color: var(--text-col);
	background-color: var(--input-bg);
	box-sizing: border-box;
	resize: none;
	font-family: 'Poppins';
}

.textarea:focus {
	border: 1px solid var(--main-col);
	outline-color: var(--main-col)
}

button.outBtn {
	margin-top:20px;
	align-self: flex-end;
}

.select>div {
	border: 1px solid var(--input-bor);
	border-radius: 5px;
	font-size: var(--fontSize);
	color: var(--text-col);
	background-color: var(--input-bg);
}