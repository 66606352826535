.loader {
  position: fixed;
  background: var(--main-col-op);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 184px;
  height: 125px;
  z-index: 99999;
  inset: 0px;
  margin: auto;
  color: rgb(255, 255, 255);
  font-size: 13px;
  box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 0.5);
}
.loader > span {
  border-color: var(--main-col)
}
