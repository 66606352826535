div.select {
  height: 24px;
  border-radius: 5px;
  min-height: 24px;
}

div.selectPrefix__control {
  height: 24px;
  border-radius: 5px;
  min-height: 24px;
  font-size: 10px;
  border-color: var(--input-bor);
}

div.selectPrefix__control.error {
  border-color: var(--cor-green-light-strok);
  background: var(--cor-green-light);
}

.selectPrefix__placeholder {
  height: 20px;
  border-radius: 5px;
  min-height: 20px;
  margin-top: 2px;
  font-size: 10px;
}

.selectPrefix__indicators {
  height: 20px;
  font-size: 10px;
}

span.selectPrefix__indicator-separator {
  margin-bottom: 2px;
  margin-top: 4px;
}

.selectPrefix__value-container {
  height: 24px;
  border-radius: 5px;
  min-height: 24px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.selectPrefix__menu {
  font-size: 10px;
}


.datapicker_new {
  width: 100%;
  padding: 0 5px;
  height: 24px;
  border-radius: 5px;
  min-height: 24px;
  border-color: var(--input-bor);
  border: 1px solid var(--input-bor);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datapicker_new.isShowRequiredFields {
  background: var(--cor-green-light);
  border-color: var(--cor-green-light-stroke);
}
.datapicker_new.isShowRequiredFieldsVal {
  background: #fbfbfb;
  border-color: var(--cor-green-light-stroke);
}

.datapicker_new.bg_color{
  background-color: var(--input-bg);
}

.label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}

.datapicker_new svg {
  max-height: 14px;
}
.datapicker_new label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
}
.dateChanges .datapicker_new {
  min-height: 38px;
  padding: 0 5px 0 10px;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.ActiveFields input {
  box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 37%);
}