.clearRow {
  display: flex;
  margin: 16px 20px 10px 20px;
}

.clearRow button {
  background-color: var(--white);
}

.aprBtnParrent {
  display: flex;
  margin: 10px 0;
}

.row {
  margin: 10px 35px;
  height: 70px;
  display: flex;
}

.cargo_row {
  margin: 10px 35px;
  display: flex;
}

div.tBox div.dense {
  padding: 0px 8px;
}

.check {
  margin: 8px 8px 8px 0;
  position: relative;
}

.cargoBox {
  display: flex;
  margin-left: 35px;
  margin-bottom: 25px;
}

.cargoHead {
  font-size: 14px;
  color: var(--main-col);
  font-weight: 500;
  margin-left: 45px;
  display: flex;
  margin-top: 15px;
}

.warning {
  color: var(--err-col);
  font-size: var(--fontSize);
}

div.mt20 {
  margin-top: 20px;
}

p.center {
  text-align: center;
}

div.centerWithM {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.filesIconBox {
  display: flex;
  direction: row;
}

.iconsBox {
  display: flex;
  align-items: center;
}

.iconsBox2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding-bottom: 5%;
}

.iconsCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.iconsCol svg {
  margin-top: 7px;
  margin-right: 10px;
}

.iconsRow {
  display: flex;
  flex-direction: row;
  gap: 5px;
  /* max-width:4vw; */
}

/* .iconsBox svg {
	margin-right:5px
}
.iconsBox svg.add {
	margin-right:15px
} */

.dialogHead > h2 {
  display: flex;
  align-items: center;
}

button.closeButton {
  justify-self: flex-end;
  transform: translate(20px, -10px);
  margin-left: auto;
}

.btnIcon2 span,
.btnIcon span {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.btnIcon2,
button.btnIcon {
  padding: 3px;
  /* max-width: 1.8vw; */
}

button.btnIcon:nth-child(2) {
  margin-right: 8px;
}

.add {
  stroke: var(--cor-col);
}

.remove {
  stroke: var(--err-col);
}

.doneLoading {
  stroke: var(--cor-col);
  transition: all 0.6s ease;
}

.doneUnloading {
  stroke: var(--err-col);
  transition: all 0.6s ease;
}

.undone {
  stroke: #c1c1c1;
  transition: all 0.6s ease;
}

.inp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.inp.start {
  justify-content: flex-start;
}

.inp h6 {
  font-size: 14px;
  color: var(--sidebar-bg-col);
  font-weight: 500;
}

.inp label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}

.inp label span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-col);
}

.inp label span.error {
  color: var(--err-col);
}

div.select {
  max-height: 38px;
}

.flexC {
  align-items: center;
  display: flex;
  justify-content: center;
}

.select > div {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  /* max-height: 35px; */
}

.select.error > div {
  background-color: #e1f3d3 !important;
  border-color: #82cd47 !important;
}

span.error,
p.error {
  color: var(--err-col);
}

span.outBtn,
button.outBtn {
  font-size: var(--fontSize);
  font-weight: 500;
  text-transform: none;
  box-shadow: 0px 1px 4px #29abe233;
  margin-right: 20px;
}

span.outBtnRed,
button.outBtnRed {
  border-color: var(--main-col);
  color: var(--main-col);
  width: 100%;
}

span.outBtnGreen,
button.outBtnGreen {
  border-color: var(--cor-col);
  color: var(--cor-col);
  width: 100%;
}

span.outBtnGreen > span {
  display: flex;
  gap: 5px;
}

span.outBtn span,
button.outBtn span {
  font-size: var(--fontSize);
}

button.outBtn svg {
  margin-left: 10px;
  width: 16px;
}

div.cont {
  padding: 10px 35px;
  margin: -10px -20px;
  width: 100%;
  /* max-height: 80%; */
  min-height: 430px;
  transition: all 0.3s ease;
}

div.cont2 {
  padding: 10px 35px;
  margin: -10px -8px;
  width: 100%;
  max-height: auto;
  min-height: 250px;
  transition: all 0.3s ease;
}

div.cont_dialog_order {
  padding: 10px 35px;
  margin: 0;
  width: 100%;
  max-height: 80%;
  min-height: 230px;
  transition: all 0.3s ease;
}

div.contTop {
  padding: 10px 35px;
  margin: 10px -8px;
  width: 100%;
}

div.WOHead.secondClass {
  padding: 0 8px 8px;
  margin-bottom: 10px;
}

div.WOHead.iconsAddRemove.secondClass {
  display: flex;
  align-items: flex-end;
  padding-bottom: 12px;
}

.br {
  border-right: 1px solid var(--text-col);
  padding-left: 5px !important;
}

.statusGrid {
  display: flex;
  margin-bottom: -12px;
}

div.labels {
  padding-bottom: 0px;
  margin-bottom: -12px;
}

.line {
  display: flex;
}

.blParent {
  position: relative;
}

.bl {
  border-left: 1px solid var(--text-col);
  height: 120px;
  position: absolute;
  left: -30px;
  top: -12px;
}

@media only screen and (max-width: 959px) {
  .bl {
    border-left: none;
  }
}

.textarea {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  padding: 10px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  box-sizing: border-box;
  resize: none;
  font-family: "Poppins";
}

.textarea:focus {
  border: 1px solid var(--main-col);
  outline-color: var(--main-col);
}

.checkBoxes {
  display: flex;
}

div.checkBoxes.pb0 {
  padding-bottom: 0;
}

div.checkBoxes.secondClass {
  padding-top: 16px;
}

.checkPar {
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.checkPar label {
  font-size: var(--fontSize);
  color: var(--text-col);
  font-weight: 500;
  margin: 3px;
}

div.notLast {
  border-bottom: 1px solid var(--text-col);
  margin-bottom: 30px;
}

div.cont2 div.notLast {
  padding-bottom: 30px;
}

.buttonCont {
  display: flex;
  align-items: center;
}

.buttonContDrawer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.mr5.secondClass {
  margin-right: 5px;
}

.cenHead {
  text-align: right;
  margin-top: 3px;
}

h2.title {
  font-size: 1.25rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 20px;
}

h6.mt10 {
  margin-top: 10px;
}

.smallerFont span {
  font-size: 0.7rem;
}

.clientAddParent {
  position: absolute;
  width: 94%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* top: 18px; */
  max-height: 38px;
  right: 2px;
}

.clientAdd {
  color: var(--light);
}

.displayNone {
  display: none;
}

.gridButtonParent {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.gridButtonParent button {
  margin-bottom: -4px;
  position: absolute;
}

.gridButtonParent label span.outBtn {
  margin-bottom: 1px;
  text-transform: capitalize;
}

.gridButtonParent label span.outBtn svg {
  padding-left: 10px;
}

.innerDrawer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 10vw 20px 10vw;
}

.checkboxList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
}

.checkboxRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.filesItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fontSize);
  width: 100%;
  padding: 3px 20px;
}

.filesItem:nth-child(2n) {
  background-color: var(--lgray);
}

.filesItem .btnIcon2,
.filesItem .btnIcon {
  height: 25px;
  width: 25px;
}

div.noOverflow {
  overflow-y: visible;
}

div.noOverflowGrand > div > div {
  overflow-y: visible;
}

div.noOverflowGrand > div {
  overflow-y: visible;
}

div.noOverflowScroll > div > div {
  overflow-y: scroll;
}

div.noOverflowScroll > div {
  overflow-y: scroll;
}

div.cont.hide {
  opacity: 0;
  transform: scale(0.2) translateY(-50%);
  max-height: 0;
  min-height: 0;
}

div.cont2.hide {
  opacity: 0;
  transform: scale(0.2) translateY(-50%);
  max-height: 0;
  min-height: 0;
}

.orderStatus {
  min-width: 600px;
}

.filesList {
  overflow: auto;
  max-height: 93px;
}

.BtnInInput_add {
  border: 0 !important;
  position: absolute !important;
  right: 9px;
  bottom: 4px;
  padding: 3px !important;
  min-width: unset !important;
  border-radius: 100px !important;
}

.inputText input {
  font-family: Poppins !important;
}

.BtnInInput_x {
  border: 0 !important;
  position: absolute !important;
  right: 1px;
  bottom: 1px;
  padding: 3px !important;
  min-width: unset !important;
  border-radius: 100px !important;
  width: 20px;
}

.tagItem {
  /* background-color: #65bc46; */
  color: var(--main-col-op);
  font-size: 12px;
  font-weight: 600;
  padding: 2px 30px 2px 10px;
  border-radius: 4px;
  display: inline-flex;
  margin-right: 6px;
}

.tagOut {
  width: 14px;
  height: 14px;
}

.transportSetBoxNew {
  background: #142f42;
  display: flex;
  border: 1px solid #d2d2d2;
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
  color: var(--white);
}

.transportSetBox {
  display: flex;
  padding: 10px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 5px;
  background: #142f42;
  color: var(--white);
  border-radius: 5px;
}

.transportSetBox label {
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}

.transportSetBoxNew label {
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #426680;
  padding-bottom: 10px;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transportSetBoxNew label button svg {
  color: var(--white);
}

.transportSetBoxNew label button {
  min-width: 26px;
}

.transportSetBox p {
  color: var(--white) !important;
  margin-bottom: 5px;
}

.lightBold {
  font-weight: 600;
}

.transportSetContent {
  display: flex;
  flex-direction: row;
  padding: 0;
  font-size: 12px;
  grid-gap: 30px;
  gap: 30px;
}

.transportSetContent > div {
  display: flex;
  gap: 10px;
}

.newOrEdit_transportSetBoxItem__3nUAf {
  display: flex;
  flex-direction: column;
}

.opacity05 {
  opacity: 0.5;
}

.flexRowLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.btnCopyTansportSet {
  font-size: 11px !important;
  padding: 0 10px !important;
  height: 100%;
}

.btnCopyTansportSet > span {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.BtnIconTag {
  top: 19px;
  min-width: unset !important;
}

.distanceAccessDuration,
.distanceLoadedDuration {
  position: absolute;
  font-size: 10px;
  font-style: italic;
  background: #fbfbfb;
  padding: 0 5px;
  color: #818181;
  bottom: -7px;
  left: 5px;
}

.orderBox {
  background: var(--bg);
  padding-top: 1px;
}

.pdfView {
  background-color: #d60000 !important;
  color: var(--white) !important;
  width: 100%;
  min-height: 50px;
}

.pdfView:hover {
  background-color: #b60000 !important;
}

.pdfView > span:first-child {
  display: flex;
  gap: 10px;
}

.noFiles {
  padding-left: 24px;
}

.receiver {
  display: flex;
  align-items: end;
  height: 150px !important;
}

.receiver label {
  font-size: var(--fontSize);
  color: var(--text-col);
  font-weight: 500;
  margin: 3px;
}

.receiverOtherContainer {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  padding-bottom: 65px;
}

.checkParAlt {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.checkParAlt label {
  font-size: var(--fontSize);
  color: var(--text-col);
  font-weight: 500;
  margin: 3px;
}

.inpAlt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.inpAlt.start {
  justify-content: flex-start;
}

.inpAlt h6 {
  font-size: 14px;
  color: var(--sidebar-bg-col);
  font-weight: 500;
}

.inpAlt label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}

.inpAlt label span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-col);
}

.inpAlt label span.error {
  color: var(--err-col);
}

.columnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 72px !important;
}

.spacer {
  height: 17px;
}

.newOrderInputContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.newOrderInput {
  font-family: "Poppins";
  height: 18px !important;
  width: 99%;
  border-radius: 5px;
  border: 1px solid black;
  padding: 3px 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.editIco {
  background-color: var(--main-col-op) !important;
}

.checkIco {
  background-color: var(--cor-col) !important;
}

.clientCarrierContainer {
  display: flex;
}

.carrierVehiclesContainer {
  display: flex;
  margin: 20px 30px;
}

.date {
  display: flex;
  border-radius: 5px;
  color: var(--text-col);
  background-color: var(--input-bg);
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 38px;
}

.datePicker {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 38px;
}

.dataPicker {
  height: 38px !important;
}

.datePicker.start {
  justify-content: flex-start;
}

.datePicker h6 {
  font-size: 10px;
  color: var(--sidebar-bg-col);
  font-weight: 500;
}

.datePicker label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}

.datePicker label span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-col);
}

.datePicker label span.error {
  color: var(--err-col);
}

.uploadBtn {
  background-color: var(--main-col-op) !important;
  height: 50px;
  color: var(--white) !important;
  text-transform: capitalize !important;
  /* font-weight: 500 !important; */
}

.saveBtn {
  background-color: var(--main-col-op) !important;
  height: 50px;
  color: var(--white) !important;
  text-transform: capitalize !important;
  /* font-weight: 500 !important; */
}

.labelContainer {
  display: flex;
  gap: 15px;
}

.infoButton {
  font-size: 13px;
  cursor: pointer;
}

.bankAccountsContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.buttonContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.stickyButtons {
  position: sticky;
  bottom: 0px;
  background-color: var(--white);
}

.statusIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusNumber {
  font-size: 12px;
}
.addressCorrection {
  width: 1000px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.addressCorrectionCheckboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
