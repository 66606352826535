
 .green{
	 color: var(--cor-col)
 }
 
 .yellow {
	 color: var(--yel)
 }
 
 .red {
	 color: var(--err-col)
 }

 .gray {
	 color: var(--text-col)
 }