.headCell {
    padding-left: 20px !important;
}

.header {
    background-color: var(--topbar-col);
    font-weight: 500;
    color: black;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    }

.top {
    min-height: 40vh !important;
    max-height: 40vh !important;
}

.table {
    min-height: 40vh !important;
    max-height: 40vh !important;
    
}


.bottom {
    max-height: 30vh !important;
}

.headerPrices {
    background-color: var(--topbar-col);
    font-weight: 500;
    color: black;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    width: 150px;
    padding-right: 25px;
    background-color: white;
}

.select {
    width: 100%
}

.dialogHead>h2 {
    display: flex;
    align-items: center;
  }
  
  button.closeButton {
    justify-self: flex-end;
    transform: translate(20px, -10px);
    margin-left: auto;
  }

  .limitData{
    text-align: center;
  }

.plus {
    color: var(--cor-col);
    background-color: var(--cor-col) !important;
}

.plusDisabled {
    color: white !important;
    background-color: var(--cor-col) !important;
    opacity: 50% !important;
}

.statusCell {
    display: flex;
    width: 100px;
    justify-content: center !important;
}

.lock {
    background-color: red !important;
}

.unlock {
    background-color: green !important;
}

.limit {
    width: 300px;
}

.checkButton{
    background-color: var(--sidebar-bg-col);
    border-radius: 10px;
    color: white;
    border: 1px solid black;
}

.limitsInfo {
    min-height: 150px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noData {
    padding-left: 10px;
}

.favStationsContainer {
    padding-left: 10px;
}

.pricesLine {
    height: 55px !important;
}

.fuelPricesTable > tbody > tr:last-of-type > td {
    border: none !important;
    outline: none;
}

.transactionHistory{
    border-right: 2px solid var(--sidebar-bg-col);
    
}

.fuelPrices{
    border-left: 2px solid var(--sidebar-bg-col);
}

.petrol{
    border-radius: 50% !important;
    width: 50px !important;
    background-color: #e7eec0 !important;
    text-align: center !important;
    justify-content: center;
    align-items: center;
}

.diesel{
    border-radius: 50% !important;
    width: 50px !important;
    height: 40px !important;
    background-color: #e8e8e8 !important;
    text-align: center !important;
    justify-content: center;
    align-items: center;
}

.adblue{
    border-radius: 50% !important;
    width: 50px !important;
    background-color: rgb(202 219 255 / 61%) !important;
    text-align: center !important;
    justify-content: center;
    align-items: center;
    padding-right: 0 !important;
    margin-right: 10px !important;
}

.lpg{
    border-radius: 50% !important;
    width: 50px !important;
    background-color: rgb(179 226 229 / 65%) !important;
    text-align: center !important;
    justify-content: center;
    align-items: center;
}

.narrow {
    min-width: 800px !important;
}

.search{
    width: 300px !important;
}