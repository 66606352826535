.root {
  width: 100%;
  background-color: var(--white);
}

.paper {
  width: 100%;
  /* margin-bottom: 2px; */
}

.table {
  min-width: 1150px;
  table-layout: fixed;
  overflow-x: scroll;
}

.tableMerge {
  width: 100%;
  table-layout: fixed;
}

.tableWOmin {
  /* min-width: 1400px; */
  table-layout: fixed;
  overflow-x: scroll;
}

table.tableSm {
  min-width: 600px;
  table-layout: fixed;
  overflow-x: scroll;
  width: auto;
}

thead.smallText th span {
  font-size: var(--smFontSize);
}

.w100child tr,
.w100child th {
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
}

table thead.tabHead th {
  color: var(--main-white);
  font-size: var(--headSize);
  font-weight: 500;
  height: 40px;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 10px;
  background-color: var(--bg);
  z-index: 12;
  border-color: var(--table-border);
}

.tabHead tr {
  height: 40px;
}

.tabBody th,
.tabBody td {
  font-size: var(--fontSize);
  padding-left: 5px;
  padding-right: 10px !important;
  border-color: var(--table-border);
}

.buttonBox_orderList {
  display: grid;
  grid-template-columns: 20px 20px 20px 20px 20px 20px 20px 20px;
  grid-template-rows: 1fr;
  gap: 2px;
  grid-template-areas: ". . . . . . . .";
  justify-content: end;
  /* display: flex;
  gap: 3px;
  justify-content: center; */
}

.buttonBox {
  display: flex;
  justify-content: flex-end;
}

.buttonBoxContainer {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 10px;
}

.buttonBoxAlt {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.buttonBoxAltGrid {
  display: grid;
  grid-template-rows: 1fr;
  gap: 5px;
  max-width: 96px;
  justify-items: center;
}

.buttonBoxParent {
  width: 150px;
}

.buttonBoxParentSm {
  width: 60px;
}

.buttonBoxParentLg {
  width: 75px;
}

td .buttonBox button {
  margin-right: 3px;
}

.tableBottom {
  width: 100%;
  height: 42px;
  min-height: unset !important;
  background-color: var(--main-col-op);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  box-sizing: border-box;
}

.tableBottom.fe {
  justify-content: flex-end;
}

.tableBottom button {
  color: var(--white);
  border: 1px solid var(--white);
  margin: 0 5px;
}

.rootFlex {
  width: 100%;
  display: flex;
}

.editBox {
  padding-right: 10px;
  border-left: 1px solid var(--white);
  box-sizing: border-box;
  flex-grow: 5;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tableBox {
  flex-grow: 2;
  width: 100%;
}

.unitsTableContainer {
  height: 34.6vh;
}

.row {
  margin: 10px 25px;
  height: 70px;
  display: flex;
}

.flexRow {
  padding: 5px 0 5px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row.tall {
  height: 130px;
}

.textareaPrn {
  width: 100%;
}

.textarea {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  padding: 10px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  box-sizing: border-box;
  resize: none;
  font-family: "Poppins";
  width: 100%;
  display: block;
}

.textarea:focus {
  border: 1px solid var(--main-col);
  outline-color: var(--main-col);
}

.cellWtDivider {
  border-right: 1px solid var(--light);
}

.headCellWtDivider {
  border-right: 1px solid var(--light);
  border-bottom: 2px solid var(--text-col);
}

.border {
  /* border-right: solid 3px var(--main-col-op); */
  padding-right: 10px;
  border-bottom: solid 3px var(--main-col-op);
}

.borRight {
  position: relative;
}

.borRight:after {
  content: " ";
  width: 3px;
  height: calc(100% + 10px);
  right: -4px;
  top: 0;
  position: absolute;
  background-color: var(--main-col-op);
}

/* .border:last-of-type{
	border-right: none;
	margin-right: 10px;
	padding-right: 10px
} */

.line {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;
}

.inp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* height: 100%; */
  margin: 0 10px;
}

.inp.invoice {
  justify-content: flex-start;
}

.invoiceHead {
  background-color: var(--lgray);
  display: flex;
  align-items: center;
}

.invoiceHead.ra {
  justify-content: flex-end;
}

.invoiceHead > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoiceHead.ra > div {
  justify-content: flex-end;
}

.invoiceHead .inp label {
  color: var(--main-col);
}

/* .inp.sm {
	width: 120px;
}

.inp.md {
	width: 260px;
}

.inp.lg {
	width: 400px;
} */

h6 {
  font-size: 14px;
  color: var(--sidebar-bg-col);
  font-weight: 500;
}

.inp label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}

.row.thin {
  height: 0px;
  margin-left: 35px;
  margin-top: 10px;
}

.check {
  color: var(--text-col);
  font-size: var(--fontSize);
  margin-left: 30px;
}

.checkPar {
  color: var(--text-col);
  font-size: var(--fontSize);
}

.outBtnRow {
  margin-left: 35px;
  margin-top: 20px;
}

button.outBtn {
  font-size: var(--fontSize);
  font-weight: 500;
  text-transform: none;
  box-shadow: 0px 1px 4px #29abe233;
}

.buttonRow button.outBtn {
  margin-right: 20px;
}

.buttonRow {
  justify-self: flex-start;
  margin-left: 50px;
  margin-right: 50px;
}

button.outBtn svg {
  margin-left: 10px;
  width: 16px;
}

.invoiceSm {
  max-width: 125px;
  min-width: 100px;
}

.invoiceMd {
  min-width: 150px;
}

.select {
  width: 100px;
}

.selectCurrency {
  width: 125px;
  margin-left: auto;
}

.collapseTable {
  border-bottom: solid 3px var(--main-col-op);
}

td.cell {
  padding: 0 10px;
}

td.cell_modalTable {
  padding: 5px;
}

.invoiceNew {
  cursor: pointer;
  color: var(--text-col);
}

thead.invoiceHead {
  background-color: #eee;
  color: var(--main-col);
  height: 40px;
}

thead.invoiceHead th {
  padding: 3px 16px;
}

td.sum {
  color: var(--main-col);
  font-weight: 500;
  font-size: 14px;
}

tr.sum {
  background-color: #eee;
  color: var(--main-col);
}

.bb {
  border-bottom: solid 5px var(--main-col-op);
}

span.status {
  width: 100%;
  height: 100%;
  align-items: center;
  padding-right: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-template-rows: 1fr;
  gap: 5px;
  grid-template-areas: ". svg";
  justify-content: end;
}

span.status svg {
  grid-area: svg;
}

.tagGrid {
  display: grid;
  grid-template-columns: 1fr 22px;
  grid-template-rows: 1fr;
  gap: 5px;
  grid-template-areas: ". .";
  align-items: center;
}

span.status_s {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
  box-sizing: border-box;
}

.alert {
  stroke: var(--yel);
}

.err {
  stroke: var(--err-col);
}

.ok {
  stroke: var(--cor-col);
}

.stickyTable {
  height: calc(100vh - 170px);
  background-color: var(--bg);
}

.stickyTableConstractors {
  height: calc(100vh - 242px);
  background-color: var(--bg);
}

.stickyDoubleTable {
  height: calc(50vh - 104px);
  background-color: var(--bg);
}

.stickyDoubleTableAlt {
  height: calc(50vh - 146px);
  background-color: var(--bg);
}

td.inputCell {
  padding: 5px;
}

table thead th.checkbox {
  width: 46px;
  padding: 10px;
}

tr td.checkbox {
  width: 46px;
  padding: 0;
}

tr td.checkbox > span {
  padding: 8px;
}

th.checkbox span,
tr.checkbox span,
span.checkbox {
  padding: 0;
}

.statusShort {
  width: 100px !important;
}

.statusLong {
  width: 100%;
}

.shortNumber {
  width: 100px;
}

.longHead {
  width: 13%;
}

.halfBox {
  width: 50%;
}

.qBox {
  width: 15%;
}

.nameBox {
  width: 17%;
}

.p10Box {
  width: 10%;
}

div.cont {
  padding: 10px 35px;
  margin: -10px -20px;
  width: 100%;
}

div.contInVoice {
  padding: 10px 0 10px 35px;
  margin: -10px 0px;
  width: 100%;
  border-bottom: solid 3px var(--main-col-op);
}

div.contInVoice .inp {
  margin-top: 10px;
}

.tableCenter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-wrap: anywhere;
  gap: 5px;
}

.mr10 {
  margin-right: 10px;
}

.positionFlag {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

div.contInVoice div.inVoicePrint {
  /* margin-top: 32px; */
}

.mt15 {
  margin-top: 15px;
}

.secondStickyRow th {
  top: 45px;
}

.dialog .tachoiconsParent {
  margin: 30px;
}

.dialog .tachoiconsParent > div {
  margin: 0 15px;
}

.dialog h3 {
  margin-left: 30px;
}

.tachoiconsParent {
  display: flex;
  justify-content: space-around;
}

.tachoiconsParent > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button.smallIcons {
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
}

button.mediumIcons {
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
}

.mediumIcons svg {
  width: 22px;
  height: 22px;
}

.smallIcons svg {
  width: 15px;
  height: 15px;
}

.timeToday,
.timeWeek {
  border-right: 1px solid var(--light);
}

.timeToday span,
.timeWeek span {
  font-size: var(--fontSize);
  top: 7px;
}

.timeWeek:before,
.timeToday:before {
  position: absolute;
  top: 0px;
  font-size: 10px;
  color: var(--text-col);
  font-weight: 300;
}

.timeToday:before {
  content: "Dziś";
}

.timeWeek:before {
  content: "Tydzień";
}

.headCellSmall span {
  font-size: 10px;
}

th.headCellSmall {
  padding: 3px;
  /* border-right: 1px solid var(--light); */
}

.searchInputSmall {
  font-size: var(--fontSize);
}

.selectClass > div {
  background-color: var(--input-bg);
  border-color: var(--input-bor);
  font-size: var(--fontSize);
}

div.selectError {
  max-height: 38px;
}

.selectError > div {
  border: 1px solid var(--err-col);
  border-radius: 5px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  max-height: 35px;
}

.selectError {
  width: 100%;
}

.reservation {
  opacity: 0.5;
}

.invoicedOrder {
  background-color: rgba(109, 231, 109, 0.925);
}

.invoiceSum {
  color: var(--text-col);
  padding-left: 15px;
}

.invoiceID {
  color: var(--text-col);
}

.tar,
.tar input {
  text-align: right;
}

.tar .selectClass div {
  display: flex;
  justify-content: flex-end;
}

.jcfe {
  justify-content: flex-end;
}

.jcfe div {
  margin: 0;
}

.subText {
  font-size: calc(var(--fontSize) * 0.8);
  font-weight: 500;
  color: var(--text-col);
  margin-left: 20px;
  margin-top: 5px;
}

.correctionText {
  font-size: calc(var(--fontSize) * 1.2);
  font-weight: 500;
  color: var(--text-col);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

/* .table tr td button,
.MuiTableRow-root tr td button {
  background: var(--sidebar-bg-col);
} */
/* button[title="Usuń"],
button[title="Usuń magazyn"],
button[title="Usuń magazyn"]:focus,
.button[title="Usuń"]:focus,
.table tr td button[title="Usuń"],
.table tr td button[title="Usuń"]:focus,
.table tr td button[title="Usuń magazyn"],
.table tr td button[title="Usuń magazyn"]:focus,
.MuiTableRow-root tr button[title="Usuń"],
.MuiTableRow-root tr button[title="Usuń"]:focus,
.MuiTableRow-root tr button[title="Usuń magazyn"],
.MuiTableRow-root tr button[title="Usuń magazyn"]:focus {
  background: var(--main-col) !important;
} */

.table tr td button[title="Usuń"]:hover,
.table tr td button[title="Usuń magazyn"]:hover,
.MuiTableRow-root tr td button[title="Usuń"]:hover,
.MuiTableRow-root tr td button[title="Usuń magazyn"]:hover {
  background: var(--main-col-hover) !important;
}

.mob_active_off {
  background: var(--err-col) !important;
}

.mob_active_on {
  background: var(--cor-col) !important;
}

.status_order svg {
  max-width: 30px;
}

.noFiles {
  padding: 20px 40px;
  font-size: 25px;
}

.halfTable {
  height: calc(50vh - 145px);
}

.fullHeiht {
  height: calc(100vh - 210px);
}

.warningOrange {
  background-color: rgba(255, 148, 8, 0.25) !important;
}

.warningRed {
  background-color: rgba(236, 34, 19, 0.25) !important;
}

.mergeSelect {
  min-width: 100%;
}

.mergePop {
  height: 360px;
}

.circleLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchangeRatesCurrency {
  text-transform: capitalize;
}

.exchangeRatesTrendUp {
  color: green;
}

.exchangeRatesTrendDown {
  color: red;
}

.exchangeRatesTrendEqual {
  color: orange;
}

.payment {
  width: 17%;
}

.paid {
  /* width: 7%; */
  text-align: center !important;
}

.isSlaveDriver {
  background: var(--yel-bg);
}

.centered {
  text-align: center !important;
}

.exchangeRates tr td:first-child {
  padding-left: 10px;
}

.sufixTableCellShort {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 15%;
}

.sufixTableCell {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
}

.summarise_col_ok,
.summarise_col_err {
  padding: 0 0 0 7px !important;
}

.summarise_col_ok > div {
  background-color: rgb(18 152 101 / 15%);
}

.summarise_col_err > div {
  background-color: rgba(152, 29, 18, 15%);
}

.summarise_col_ok > div,
.summarise_col_err > div {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.summarise_col_ok svg,
.summarise_col_err svg {
  width: 20px;
}

.tableBoxInColumnA > div,
.tableBoxInColumnB > div {
  border: 1px solid rgb(21 47 66 / 15%);
  border-radius: 5px;
  min-height: 100px;
  padding: 0 10px;
  width: 99%;
}

.tableBoxInColumnA_full > div,
.tableBoxInColumnB_full > div {
  border: 1px solid rgb(21 47 66 / 15%);
  border-radius: 5px;
  min-height: 100px;
  padding: 0 10px;
  width: 100%;
}

.tableBox_border > div {
  border: 1px solid rgb(21 47 66 / 15%);
  border-radius: 5px;
  padding: 0 10px;
  width: 100%;
  height: auto;
  margin-bottom: 36px;
}

.tableBox_border > div td {
  font-size: 12px;
}

.doc_checkbox {
  max-width: 26px;
  width: 26px;
  min-width: unset;
}

.doc_checkbox,
.doc_checkbox > span {
  padding: 3px 0px !important;
}

.doc_checkbox,
.doc_checkbox > span * {
  font-size: 18px !important;
}

.noData {
  color: var(--main-col);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  background: rgb(255 0 0 / 5%);
  padding: 7px 10px !important;
}

.tableBoxInColumnB > div {
  margin-left: 1%;
}

.tableBoxInColumnB_full > div {
  margin-left: 0%;
}

.tableSummriseRow {
  min-height: 35px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(220 224 227);
  margin: 10px 0;
  color: #424242;
  font-size: 14px;
}

.qrcode {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 25px 0;
}

.flexRowMain {
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-template-rows: 1fr;
  gap: 5px;
  grid-template-areas: ". .";
}

.flexRow {
  display: flex;
  gap: 2px;
  justify-content: flex-end;
}

.check2 {
  margin: 0 10px 0 0;
}

.columnCelFlex {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
}

.green {
  background-color: var(--cor-col) !important;
}

button.green[disabled] {
  background: var(--main-disabled) !important;
  color: #8b8b8b;
  opacity: 1;
}

.colorRow {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 24px;
}

.colorBox {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
}

.infoTooltipContainer {
  color: green !important;
}

.infoTooltip {
  color: var(--text-col) !important;
  height: 8px !important;
}

.paymentCont {
  display: flex;
}

.paymentVal {
  width: 75%;
}

.paymentCurr {
  width: 15%;
};

.noOrderTable {
  height: calc(100vh - 210px);
  background-color: var(--bg);
};

.test {
  height: 66.8px;
};
.typesCol {
  color: red;
}

.withFilter {
  display: flex !important;
  gap: 10px;
}
