.input {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  padding: 11px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  box-sizing: border-box;
  transition: all 0.3s ease;
  height: 38px;
}

.input.error {
  /* border-color: var(--err-col); */
  background-color: var(--cor-green-light) !important;
  border-color: var(--cor-green-light-stroke) !important;
}

.input:focus {
  border: 1px solid var(--main-col);
  outline-color: var(--main-col);
}

.input:disabled {
  background-color: var(--input-dis);
}

.searchBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.small {
  max-width: 140px;
}

.searchIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 20px;
  height: 20px;
  stroke: var(--input-bor);
  cursor: pointer;
}

.clickAble {
  cursor: pointer;
  stroke: var(--main-col-op);
  transition: stroke 0.3s ease;
}

.clickAble:hover {
  stroke: var(--main-col);
}
