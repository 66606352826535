.progress {
  width: 100%;
  height: 18px;
  border-radius: 9px;
  position: relative;
  overflow: hidden;
  box-shadow: var(--input-bor) 0 2px 3px 0px;
}

.progress.green {
  background-color: var(--cor-col-bg);
}

.progress.yellow {
  background-color: var(--yel-bg);
}

.progress.red {
  background-color: var(--err-col-bg);
}

.bar {
  height: 100%;
  padding-left: 10px;
  color: var(--white);
  font-weight: 600;
  text-shadow: var(--sidebar-bg-col) 0 0 3px;
  transition: all 0.5s ease;
  width: 0px;
}

.bar.green {
  background: repeating-linear-gradient(
    135deg,
    var(--cor-col),
    var(--cor-col) 0.3em,
    var(--cor-col) 0.3em,
    var(--cor-col) 0.6em
  );
}

.bar.yellow {
  background: repeating-linear-gradient(
    135deg,
    var(--yel),
    var(--yel) 0.3em,
    var(--yel) 0.3em,
    var(--yel) 0.6em
  );
}

.bar.red {
  background: repeating-linear-gradient(
    135deg,
    var(--err-col),
    var(--err-col) 0.3em,
    var(--err-col) 0.3em,
    var(--err-col) 0.6em
  );
}
