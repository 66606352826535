.title {
  width: 100%;
  height: 40px;
  background-color: var(--main-col-op);
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: var(--white);
  font-weight: 600;
  font-size: 16px;
  position: relative;
  padding: 10px 20px;
}

.right {
  position: absolute;
  right: 20px;
  top: 1px;
  display: flex;
  align-items: baseline;
}
.right_t0 {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: baseline;
}

.right > *, .right_t0 > *{
  margin-left: 10px;
}
.newRightCorner > div {
  margin: 0;
}
.newRightCorner {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 0px 9px;
  gap: 0px 9px;
  grid-template-areas: ". . . . . ";
  z-index: 999;
  align-items: center;
}
.datapicker{
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  height: 38px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.datapicker svg {
  width: 15px;
}
.datapicker label {
  min-width: 100px;
  border-right: 1px solid #cccccc;
}
.react-datepicker__tab-loop {
  position: absolute;
}
.rightSide{
  height: 100%;
  display: flex;
  align-items: center;
}
.selectBox{
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.selectBox > div:first-child{
  font-size: 12px;
}
.selectBox > div:last-child{
  min-width: 200px
}

.searchBox{
  position: relative;
  padding-left: 5px;
}
.searchInput{
	border: 1px solid var(--input-bor);
	border-radius: 5px;
	/* padding: 11px; */
	font-size: var(--fontSize);
	color: var(--text-col);
	background-color: var(--input-bg);
	box-sizing: border-box;
	transition: all .3s ease;
	height: 26px;
}

.searchInputIcon{
	position:absolute;
	right: 8px;
	top: 3px;
	width:20px;
	height:20px;
	stroke: var(--input-bor)
}