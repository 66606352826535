.scrollFixGMAP {
    background-color: #fafafa;
    font-size: 12px;
    font-weight: 500;
    width: 292px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24) !important;
    padding: 4px;
    border: 1px solid var(--main-disabled) !important;
}

.scrollFixGMAP table tr.tr_can img {
    width: 25px;
    height: auto;
    margin: 2px;
    border-radius: 6px;
    background-color: var(--white);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27) !important;
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.27) !important;
}

.scrollFixGMAP table tr.tr_can {
    background: rgba(156, 161, 165, 1);
    background: -moz-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(156, 161, 165, 1)), color-stop(100%, rgba(140, 144, 148, 1)));
    background: -webkit-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: -o-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: -ms-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: linear-gradient(to bottom, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ca1a5', endColorstr='#8c9094', GradientType=0);
}

.scrollFixGMAP table tr:first-child {
    background-color: #404952;
    height: 53px;
    color: var(--white);
}

.parentContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". .";
}

/*.scrollFixGMAP table tr:last-child {
    background-color: #eaeaea;
}*/
.gm-style-iw div {
    display: table !important;
}

.scrollFixGMAP table {
    border: 0 !important;
    border-collapse: collapse;
}

.scrollFixGMAP .pop_carname {
    margin-left: 8px;
    font-weight: bold;
}

.scrollFixGMAP .pop_speed {
    font-size: 38px;
    font-family: roboto;
    position: relative;
    right: -21px;
    top: 3px;
    font-weight: 300;
    float: left;
}

.kmhClass {
    float: left;
    margin-left: 24px;
}

.imieKierowcyClass {
    font-weight: 100 !important;
    font-size: 11px !important;
}

.pop_carname {
    font-size: 14px !important;
}

.scrollFixGMAP .pop_speed_red {
    color: var(--main-col) !important;
}

.scrollFixGMAP .pop_other_left {
    height: 16px !important;
    line-height: 16px !important;
    padding-left: 7px;
    padding-bottom: 2px;
    font-weight: bold;
    min-width: 100px;
    font-size: 9px !important;
}

.scrollFixGMAP .pop_other_right {
    padding-right: 7px;
    line-height: 16px !important;
    font-size: 9px !important;
    min-width: 150px;
}

.scrollFixGMAP .pop_other_header {
    font-size: 15px;
    font-weight: bold;
    line-height: 20px !important;
    padding-left: 7px;
    padding-bottom: 2px;
}


.tableCarList {
    float: left;
    width: 300px;
    height: 680px;
    background-color: var(--main-disabled);
}

.root {
    width: '100%';
}

.container {
    max-height: 680px;

}

.markerOverlay {
    position: absolute;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background-color: var(--main-col-hover);
    border-radius: 50%;
    opacity: 0.3;

}


.hide-after::after {

    top: -1000px;
}

.mapLayaout {
    background-color: #f4f4f4;
}

.routePlaner {
    position: absolute;
    top: 52px;
    left: 600px;

    width: 200px;
    height: 300px;

    background-color: var(--white);
}

.routePlaner .ulPoints {
    padding-left: 0px;
}

.btn {
    cursor: pointer;
    color: var(--text-col);
    border-color: var(--input-bor);
    border-radius: 30px;
    min-width: 58px !important;
}

.icon {
    color: var(--main-col);
}

.icon:hover {
    color: var(--main-col-op);
}

.routePlaner .liPoints {
    list-style-type: none;
}

.routePlaner .dragPoints {
    width: 20px;
    height: 20px;
    background-color: var(--main-disabled);
    float: left;
    cursor: pointer;
}

.searchInputContainer {
    position: absolute;
    top: 52px;
    left: 600px;

    width: 150px;

    background-color: var(--white);
}

.pop_other_left {
    font-size: 85%;
    text-align: left;
    padding: 0px;
    height: 9px;
    line-height: 9px;
}

.pop_other_right {
    font-size: 85%;
    text-align: right;
    padding: 0px;
    height: 9px;
    line-height: 9px;
}

.renderOption {
    font-size: 10px;
}


.noSuggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    position: absolute;
    cursor: default;
    padding: 0 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999;
    width: calc(300px + 1rem);
    background-color: var(--white);
}

.tableOrderSummary {
    width: 100%;
    border-collapse: collapse;
}

.tableOrderSummary tr td {
    padding: 2px;
}


.tableOrderSummary .header {
    font-weight: bold;
}

.tableOrderSummary tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tableRoutingSummary {
    width: 100%;
    border-collapse: collapse;
}

.tableRoutingSummary tr td {
    padding: 2px;
}

.tableRoutingSummary .header {
    font-weight: bold;
    width: 155px;
}

.tableRoutingSummary tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tablePopupMyCustomMarker tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tableCarPopup tr:nth-child(even) {
    background-color: #f2f2f2;
}


.tablePopupMyCustomMarker tr td {
    padding: 2px;
    font-size: 9px;
}


.suggestions li {
    padding: 0.5rem;
}

.suggestionActive,
.suggestions li:hover {
    background-color: #f4f4f4;
    cursor: pointer;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid var(--main-disabled);
}

.pacIcon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-size: 34px;
    background-position: 0px 100px;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}

.pacItem {
    cursor: default;
    padding: 0 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999;
}

.mapCounter {
    position: absolute;
    bottom: 30px;
    left: 558px;
    z-index: 9;
    background-color: var(--bg);
    color: var(--text-col);
    width: 111px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.orderSummaryBox {
    position: absolute;
    bottom: 23px;
    right: 8px;
    z-index: 9;
    width: 210px;
    background-color: var(--bg);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-size: 10px;
    border: 1px solid var(--main-disabled);
    padding: 6px;
}

.routingHereSummaryBox {
    /* position: absolute;
    top: 96px;
    right: 30px; */
    z-index: 9;
    width: 270px;
    background-color: var(--bg);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
    font-size: 10px;
    border: 1px solid var(--main-disabled);
    padding: 6px;
}


.mapCounter .icon {
    padding: 5px;
    /* margin: 2px 5px; */
}

.mapCounter .btn {
    margin: 2px 2px;
}

/* .searchBox {
    width: 340px;
    height: auto;
    background-color: var(--bg);
    position: absolute;
    left: 20px;
    top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px 15px 5px;
}

.searchBoxHead{
    width: 340px;
    height: 34px;
    float: left;
    background-color: var(--lgray);
    display:flex;
    align-items:center;
} */

/* .fuelIcon svg{
    margin: 0 !important;
    width: 21px;
    height: 21px;
    fill: #ed3c22;
}
.fuelIcon.active svg{
    fill: var(--main-col-op) !important;
}
.fuelSearchBox{
    width: 410px;
} */

.searchBox {
    width: 354px;
    height: auto;
    background-color: var(--bg);
    position: absolute;
    left: 8px;
    top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px 15px 5px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 24%);
    border-radius: 2px;

}

.searchBoxHead {
    width: 354px;
    height: auto;
    float: left;
    background-color: var(--lgray);
    display: flex;
    align-items: center;
    border-radius: 2px 2px 0 0;
    justify-content: space-between;
}

.fuelIcon svg {
    margin: 0 !important;
    width: 21px;
    height: 21px;
    fill: var(--main-col);
}

.mapOsm.active svg,
.mapRoadmap.active svg,
.mapHybrid.active svg,
.mapHere.active svg,
.showRouteMode.active svg,
.showSearchAndPlanMode.active svg,
.fuelIcon.active svg {
    fill: var(--main-col-op) !important;
}

.fuelSearchBox {
    padding: 10px 10px 0px 10px;
    width: 352px;
    margin-top: 4px;
}



.fuelSearchBoxOptions {
    font-size: 11px
}

/* e100 */

.scrollFixGMAPE100 {
    background-color: #fafafa;
    font-size: 12px;
    font-weight: 500;
    width: 330px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24) !important;
    padding: 4px;
    border: 1px solid var(--main-disabled) !important;
}

.scrollFixGMAPE100 table tr.tr_can img {
    width: 25px;
    height: auto;
    margin: 2px;
    border-radius: 6px;
    background-color: var(--white);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27) !important;
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.27) !important;
}

.scrollFixGMAPE100 table tr.tr_can {
    background: rgba(156, 161, 165, 1);
    background: -moz-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(156, 161, 165, 1)), color-stop(100%, rgba(140, 144, 148, 1)));
    background: -webkit-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: -o-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: -ms-linear-gradient(top, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    background: linear-gradient(to bottom, rgba(156, 161, 165, 1) 0%, rgba(140, 144, 148, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ca1a5', endColorstr='#8c9094', GradientType=0);
}

.scrollFixGMAPE100 table tr:first-child {
    background-color: var(--main-col-op);
    height: 53px;
    color: var(--white);
}

.modalE100Header {
    background-color: var(--main-col-op);
    max-height: 30px;
    margin: -5px 0 0 -4px;
    width: 329px !important;
}

.modalE100Name {
    color: var(--white);
    font-size: 13px;
    max-width: 236px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 6px 0 0 6px
}

.modalE100Logo {
    position: relative;
    width: 26px;
    top: 2px;
    margin-left: 3px;
}

.modalE100Header button {
    background: transparent;
    border: 0;
    color: var(--white);
    padding: 0;
    margin: 0;
    position: relative;
    top: 3px;
    right: 0px;
    cursor: pointer;
}

.modalE100Header button:hover {
    opacity: .65;
}

.modalE100Body {
    padding: 5px;
    gap: 6px;
}

.modalE100Body h3 {
    font-size: 11px;
    /* margin: 0 0 8px 0; */
}

.fuelSearchBox h3 {
    font-size: 12px;
}

.circlePrice {}

.circlePrice p {
    margin: auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.fuelName {
    font-weight: 400;
    font-size: 11px;
}

.modalE100CloseButton {
    width: 100%;
    height: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0 !important;
}

.modalE100CloseButton svg {
    width: 18px;
}

.hr {
    background: #d4d4d4;
    height: 1px;
    display: block;
    width: 100%;
}

.fuelNameLastTransaction {
    border-radius: 7px;
    padding: 0px 8px;
}

.gridRow {
    padding-bottom: 2px;
    font-weight: 400;
    font-size: 11px;
}

.listRow {
    font-weight: 400;
    font-size: 11px;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.btnSearch {
    background: var(--main-col-op) !important;
    color: var(--white) !important;
    height: 30px;
    width: 100%;
    text-transform: none !important;
}

.distanceList {
    padding: 8px 3px 0 3px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.searchBoxFlex {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    min-width: 335px;
    gap: 10px;
}

.searchBoxFlex button {
    border: 0;
    display: flex;
    align-items: center;
    height: 30px;
    width: 34px;
    cursor: pointer;
    background: var(--main-col);
    color: var(--white);
    font-weight: bold;
    border-radius: 4px;
    padding: 0;
    justify-content: center;
}

button.reset {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    height: 15px;
    cursor: pointer;
}

.searchBoxFlex button:hover,
button.reset:hover {
    opacity: .7;
}

.searchBoxFlex button svg,
button.reset svg {
    width: 15px;
    height: 15px;
}

.searchInputE100 {
    width: 100% !important;
}

.searchInput {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 330px;
    height: 32px;
    padding: 8px 26px 8px 12px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
    font-size: 13px;
    outline: none;
    /* margin-top: 4px; */
    float: left;
}

.flexRow {
    display: flex;
    gap: 3px;
    align-items: center;
}

.flexRow small {
    position: relative;
    top: 1px;
}

.searchBoxHead_mapType {
    display: flex;
    width: 100%;
    justify-content: space-around;
    box-shadow: inset -1px 0 0 0 #e6e6e6;
}


.searchBoxHead_mapType button.active svg {}

.searchBoxHead_mapOptions {
    background: white;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.searchBoxHead_mapOptions button,
.searchBoxHead_mapType button {
    min-width: unset !important;
    font-size: 11px;
    width: 100%;
    padding: 6px 0;
    border-radius: 0;
}


.searchBoxHead_mapOptions button.active svg {}

.searchBoxHead_mapOptions button.active {
    background: #f6f6f6;
}

.searchBoxHead_mapType button.active {
    background: #e6e6e6;
}

.searchRouteBtn {
    background-color: var(--btn-dark);
    color: var(--white);
    fill: var(--white);
    border: 0;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    /* box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 300;
}

.stationsListRowsBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.searchRouteBtn:hover {
    background-color: var(--btn-dark-hover);
}

.stationsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.stationsList>div {
    border-bottom: 1px solid #d4d4d4;
}

.stationsList>div:last-child {
    border-bottom: 0;
}

.stationsListRow {
    display: grid;
    grid-template-columns: 7px 30px 1fr auto auto;
    grid-template-rows: 1fr;
    grid-gap: 6px;
    gap: 6px;
    grid-template-areas: ". . . .";
    font-size: 12px;
    align-items: center;
}

.stationsListRow2 {
    display: grid;
    grid-template-columns: 30px 1fr auto auto;
    grid-template-rows: 1fr;
    grid-gap: 6px;
    gap: 6px;
    grid-template-areas: ". . . .";
    font-size: 12px;
    align-items: center;
}

.stationsListRow2>div {
    display: flex;
    font-size: 11px;
    height: 100%;
    align-items: center;
    line-height: 18px;
}

.stationsListRow>div:first-child {
    text-align: center;
}

.stationsListRow>div:last-child {
    display: flex;
    align-items: center;
    gap: 5px;
}

.distances {
    background-color: #eeeeee;
    padding: 10px;
    border-radius: 4px;
}

.distances h3 {
    padding: 0 0 7px 0;
    display: flex;
    gap: 7px;
    align-items: center;
}

.distances button svg {
    max-height: 16px;
}

.link {
    display: flex;
    width: auto;
    align-items: center;
    gap: 5px;
}

.fuelSearchBoxOptions label>span {
    padding: 0 0 0 0 !important;
    margin: 0 0px 0 10px !important;
}

.radioRow {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

div.select {
    max-height: 38px;
}

.flexC {
    align-items: center;
    display: flex;
    justify-content: center;
}

.select>div {
    border: 1px solid var(--input-bor);
    border-radius: 5px;
    font-size: var(--fontSize);
    color: var(--text-col);
    background-color: var(--input-bg);
    /* max-height: 35px; */
}

.select.error>div {
    /* border-color: var(--err-col); */
    background-color: var(--cor-green-light) !important;
    border-color: var(--cor-green-light-stroke) !important;
}

.input {
    border: 1px solid var(--input-bor);
    border-radius: 5px;
    font-size: var(--fontSize);
    color: var(--text-col);
    background-color: var(--input-bg);
    height: 28px;
    padding: 0px 10px;
    box-sizing: border-box;
    width: 85px;
}

.showResult {
    background-color: var(--cor-col);
    color: var(--btn-withe);
    font-size: 12px;
    min-width: 64px;
    box-sizing: border-box;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Poppins;
    font-weight: 500;
    line-height: 1.75;
    width: 100%;
    max-width: 385px;
    border-radius: 4px;
    border: 0;
    margin: 10px auto 0 auto;
    cursor: pointer;
    padding: 3px 0;
    display: flex;
    justify-content: center;
}

.showResult:hover {
    opacity: .8;
}

.ifCarSelectedRow {
    font-size: 12px;
    font-weight: 600;
    gap: 4px;
    background: #eeeeee;
    padding: 5px;
    color: var(--main-col-op);
    margin-bottom: 6px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    align-items: center;
}

.dotts {
    height: 2px;
    background-image: radial-gradient(circle, #000 1px, transparent 1px);
    background-size: 6px 3px;
    position: relative;
    top: -6px;
    margin: 0 6px;
    opacity: 0.3;
}

.circlePrice p {
    padding: 0 7px;
    align-items: flex-end;
    gap: 3px;
}

.circlePrice p small {
    font-size: 9px;
    position: relative;
    top: 1px;
    font-weight: 600;
}

.fuelBox {
    display: flex;
    gap: 3px;
    flex-direction: column;
}

.fuelBox2 {}

.fuelItem {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas: ". . .";
    align-items: end;
}

.fuelItem2 {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas: ". . . .";
    align-items: end;
}

.fuelItem2 .dotts {
    top: -4px;
}

.windowOptions {
    display: flex;
    gap: 3px;
    height: 98%;
    align-items: center;
    margin-right: 5px;
}

.modalE100MinimizeButton,
.modalE100MaximizeButton {
    height: 100%;
    top: 0 !important;
    padding: 0 2px !important;
}

/*  */

.mapCounter {
    position: absolute;
    bottom: 30px;
    left: 558px;
    z-index: 9;
    background-color: var(--bg);
    color: var(--text-col);
    width: 156px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px
}

.orderSummaryBox {
    position: absolute;
    bottom: 23px;
    right: 8px;
    z-index: 9;
    width: 210px;
    background-color: var(--bg);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-size: 10px;
    border: 1px solid var(--main-disabled);
    padding: 6px;
}

/* .routingHereSummaryBox {
    position: absolute;
    top: 96px;
    right: 30px;
    z-index: 9;
    width: 270px;
    background-color: var(--bg);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
    font-size: 10px;
    border: 1px solid var(--main-disabled);
    padding: 6px;
} */

.routingHereTollBox {
    position: absolute;
    top: 96px;
    left: 30px;
    z-index: 9;
    width: 350px;
    height: auto;
    max-height: 310px;
    background-color: var(--bg);
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 24%);
    font-size: 10px;
    border: 1px solid var(--main-disabled);
    padding: 6px;
}



/* 
.routingHereTollBox::-webkit-scrollbar {
    display: none;
} */
.grid {
    display: grid;
    grid-template-columns: 20px auto 1fr 65px 65px;
    grid-template-rows: 1fr;
    grid-gap: 0;
    gap: 0;
    grid-template-areas: ". . . . .";
    padding: 2px 4px;
    cursor: pointer;
}


.mapCounter .icon {
    padding: 5px;
    /* margin: 2px 5px; */
}

.mapCounter .btn {
    margin: 2px 2px;
}

/* .searchBox {
    width: 340px;
    height: 80px;
    background-color: var(--bg);
    position: absolute;
    left: 20px;
    top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchBoxHead {
    width: 340px;
    height: 34px;
    float: left;
    background-color: var(--lgray);
    display: flex;
    align-items: center;
} */

.popupHeader {
    font-weight: bold;
    padding: 0;
    display: flex;
    justify-content: space-between;
    font-size: 11px;
}

.popupHeaderLoader {
    font-weight: bold;
    padding: 0;
    justify-content: space-between;
    font-size: 11px;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr;
    gap: 5px;
    grid-template-areas: ". . . .";
    align-items: center;
}

.popupBody {
    padding: 6px 1px 1px 1px;
    max-height: 285px;
    overflow: auto;
}

.popupBody::-webkit-scrollbar {
    width: 3px;
}

.popupBody::-webkit-scrollbar-track {
    background-color: var(--white)
}

.popupBody::-webkit-scrollbar-thumb {
    background: var(--main-to-op);
}

.popupBody::-webkit-scrollbar-thumb:hover {
    background: var(--main-to-op);
}

.popupBody>div:nth-child(odd) {
    background-color: #f2f2f2;
}

.popupBodyStyledRows>div:last-child {
    border-bottom: 1px solid var(--main-col);
    padding-bottom: 3px;
}

.popupBody img {
    position: relative;
    top: 2px;
}

.popupBody small {
    position: relative;
    top: 1px;
    font-size: 8px;
}

.popupSummerise {
    display: flex;
    justify-content: space-between;
    color: var(--white);
    font-weight: bold;
    background: var(--main-col);
    padding: 3px 4px 3px 5px;
    font-size: 11px;
    max-width: 254px;
}

.popupSummeriseAdditional {
    color: var(--main-col);
    padding: 3px 4px 3px 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 254px;
}

.popupSummeriseAdditionalRow {
    font-weight: 700;
    font-size: 10px;
    display: grid;
    grid-template-columns: auto auto 1fr 65px 65px;
    grid-template-rows: 1fr;
    gap: 5px;
    grid-template-areas: ". . . . .";
}

.popupSummeriseAdditionalRow>div:first-child {
    display: flex;
    align-items: center;
}

.popupSummeriseAdditionalRow>div:nth-child(2) {
    font-weight: 500;
}

.tollName {
    position: relative;
    top: 1px;
    font-size: 9px;
    font-weight: 500;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.collapse {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.collapse:hover {
    opacity: .6;
}

.collapse svg {
    height: 15px;
    width: 15px;
}

.e100Icon {
    display: flex;
    align-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    top: 1px;
    left: -4px;
}

.e100Icon img {
    max-width: 27px;
    height: auto;
    position: relative;
    top: -2px;
}

.mapInfo {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: auto;
    height: 100%;
    flex-direction: column;
    padding: 10px;
    overflow: auto;
    pointer-events: none;
}

.mapInfo>* {
    pointer-events: all;
}

.mapView {
    position: relative;
    width: 100%;
    height: 100%;
}

.mapInfo::-webkit-scrollbar {
    width: 0.3em;
    background-color: transparent;
}

.mapInfo:hover::-webkit-scrollbar {
    width: 0.3em;
    background-color: transparent;
}

.mapInfo::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.mapInfo:hover::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(21, 21, 21, 0.19);
}

.flexRow_Ico {
    display: flex;
    padding: 0 5px;
}

.flexRow_Ico svg {
    width: 15px;
}

.flexRow_Name {
    cursor: pointer;
    width: 100%;
}

.flexRow_Name span {
    display: flex;
    gap: 3px;
    align-items: center;
}

.flexRow_Name span img {
    top: 0;
}

.flexRow_Btns {
    display: flex;
    gap: 3px;
    align-items: center;
}

.iconAdd,
.iconEdit,
.iconDel {
    max-width: 18px;
    max-height: 18px;
}

.iconAdd[disabled],
.iconEdit[disabled],
.iconDel[disabled] {
    background-color: var(--white-op) !important;
}

.iconAdd {
    background-color: var(--cor-col) !important;
}

.iconAdd:hover {
    background-color: var(--cor-col-bg) !important;
}

.iconEdit {
    background-color: var(--btn-dark) !important;
}

.iconEdit:hover {
    background-color: var(--main-to-op) !important;
}

.mapCursorPointer {
    cursor: pointer;
}

.mapCursorNormal {
    cursor: auto;
}