.mainContent{
  display: flex;
  padding: 0;
  margin-top: 15px;
}


.topContent{
  display: flex;
  gap: 50px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px;
}
.lowContent{
  padding: 10px 20px;
}
.midContent{
  display: flex;
  padding: 30px 20px;
  background: #f1f3f4;
  margin-top: 20px;
}

.midContentLeft{
  display: flex;
  padding: 0 25px 0 0;
  gap: 10px;
}

.midContentRight{
  display: flex;
  padding: 0 0 0 25px;
  gap: 10px;
}

.inp {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  gap: 10px;
  color: black !important;
  font-weight: 600;
}

input:focus::placeholder {
  color: transparent;
}

.inp.start {
  justify-content: flex-start;
}

.inp h6 {
  font-size: 10px;
  color: var(--sidebar-bg-col);
  font-weight: 500;
}

.inp label {
  font-size:11px;
  font-weight: 600;
  color: var(--text-col);
  width: 30%;
  text-align: start;
}

.inp label span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-col);
}

.inp label span.error {
  color: var(--err-col);
}

.inputText input{
  font-family: Poppins !important;
}

.select > div {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  /* max-height: 35px; */
}

div.selectError {
  max-height: 38px;
}

.selectError > div {
  border: 1px solid var(--cor-green-light-stroke);
  border-radius: 5px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--cor-green-light);
  max-height: 35px;
}

.selectError{
  width: 100% !important;
}

.select{
  width: 100% !important;
}

.date{
  display: flex;
  border-radius: 5px;
  color: var(--text-col);
  background-color: var(--input-bg);
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.invoisPicker{
  height: 38px !important;
}

.invoisPicker label{
  margin: 0 !important;
  text-align: left;
  padding: 0 10px
}

.datePicker {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  gap: 5px;
}

.datePicker.start {
  justify-content: flex-start;
}

.datePicker h6 {
  font-size: 10px;
  color: var(--sidebar-bg-col);
  font-weight: 500;
}

.datePicker label {
  font-size:11px;
  font-weight: 600;
  color: var(--text-col);
  width: 80%;
  margin: 10px;
}

.datePicker label span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-col);
}

.datePicker label span.error {
  color: var(--err-col);
}

.services{
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  color: var(--text-col);
  background-color: var(--input-bg);
  min-height: 100px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.mB10{
  margin-bottom: 10px !important;
}

.servicesTableHead{
  width: 100%;
}

.table {
  height: calc(100% + 170px);
  background-color: var(--bg);
}

.summary {
  width: 250px;

}

.cell{
  width: 10% !important;
  text-align: center !important;
  font-size: 12px !important;
}

.cellAlt {
  overflow-wrap: break-word;
  font-size: 12px;
}

.dataPicker{
  height: 38px !important;
}
.dataPicker label{
  text-align: left;
}
.summaryRow{
  background-color: #f1f3f4;
}
.addAccount{
  text-align: end;
  cursor: pointer;
  font-size: 10px;
  height: 16px
}

.emptySmall{
  height: 16px;
}

.emptyRow {
  height: 38px;
}

.noteAmount{
  width: 20%;
}

.receiverBox {
  height: 10px;
  width: 10px;
  font-size: 10px;
}

.receierBoxText {
  font-size: 10px;
}

.addReceiver {
  display: flex;
  gap: 2px;
  font-size: 13px;
  height: 16px;
  line-height: 13px;
  justify-content: flex-end;
  align-items: center;
}

.emptyRowAlt{
  height: 12px;
}
.emptyRowAlt2{
  height: 10px;
}

.addReceiverAndAcc{
  display: flex;
  flex-direction: column;
}