.mapBox {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    "."
    ".";
}

.label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}
