@import url("./font/poppins.css");

:root {
  --sidebar-bg-col: #142f42;
  --sidebar-open: #142f4280;
  --sidebar-hover: #142f42;
  --btn-stroke: #fff;
  --btn-light: #345167;
  --btn-light-hover: #4a6e8a;
  --btn-dark: #142f42;
  --btn-dark-hover: #142f42;
  --btn-withe: #fff;
  --btn-withe-hover: #152f4207;
  --main-col: #ed3c22;
  --main-col-hover: #d43118;
  --main-white: #152f42;
  --main-col-op: #152f42;
  --main-col-op2: #152f4288;
  --main-to-op: #152f42;
  --main-disabled: #cccccc;
  --highlight: #5867dd22;
  --text-col: #707070;
  --third-col: #58cfdd;
  --err-col: #ed3c22;
  --err-col-op: #d43118;
  --err-col-bg: #d43118;
  --cor-col: #129865;
  --cor-col-bg: #a8ffcb;
  --cor-green-light: #e1f3d3;
  --cor-green-light-stroke: #82cd47;
  --topbar-col: #f1f3f4;
  --headtxt-col: #1f2538;
  --white: #ffffff;
  --white-op: #ffffff99;
  --shadow: #00000077;
  --input-bor: #aaaaaa;
  --input-bg: #fbfbfb;
  --input-dis: #eee;
  --light: #ccc;
  --yel: #edc422;
  --yel-bg: #f9ffa8;
  --lgray: #eeeeee;
  --lgray2: #eeeeee99;
  --bg: #ffffff;
  --bg_plus: #ffffff;
  --table-border: #e0e0e0;
  --fontSize: 12px;
  --fontSize_min: 11px;
  --headSize: 14px;
  --smFontSize: 10px;
  --gradientNewsYellow: linear-gradient(90deg, rgba(247, 223, 9, 0.47) 0%, rgba(247, 222, 9, 0) 50%);
  --gradientNewsGreen: linear-gradient(90deg, rgba(18, 152, 101, 0.25) 0%, rgba(18, 152, 101, 0) 50%);
  --gradientNewsBlue: linear-gradient(90deg, rgba(88, 139, 221, 0.229) 0%, rgba(88, 207, 221, 0) 50%);
  --newsColor: #142F42;
  --newsDefaultBtnBg: #142F42;
  --bg_plus: #ffffff;
  --bg_green: #129865;
  --bg_light: #f1f3f4;
  --login_bg: url(../img/log_bg_dark_2.jpg);
  --slidebar_bg: url(../img/log_bg_md.jpg);
}

@media (max-width: 1600px) {
  :root {
    --smFontSize: 9px;
    --fontSize: 12px;
    --headSize: 12px;
  }
}

@media (max-width: 1450px) {
  :root {
    --smFontSize: 8px;
    --fontSize: 12px;
    --headSize: 10.5px;
  }
}

div.selectPrefix__value-container {
  font-size: var(--smFontSize);
}

html {
  background-color: var(--bg);
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
p,
a,
em,
img,
strong,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
article,
aside,
canvas,
details,
embed,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: var(--fontSize);
  font: inherit;
  line-height: 1.5em;
  vertical-align: baseline;
  font-family: Poppins;
  box-sizing: border-box;
}

h3 {
  font-size: 14px;
  font-weight: bold;
}

.mainView-enter {
  opacity: 0.01;
}

.mainView-enter.mainView-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.mainView-leave {
  opacity: 1;
}

.mainView-leave.mainView-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.MuiTableCell-root.MuiTableCell-body {
  color: var(--text-col);
}

span.MuiIconButton-label {
  color: var(--main-col-op);
}

.MuiPaper-root {
  color: var(--text-col);
}

span.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: var(--main-to-op);
}

button.MuiButton-contained.Mui-disabled {
  background-color: var(--main-disabled);
  color: #959393;
}

button.MuiButton-contained.Mui-disabled span svg {
  color: #8b8b8b;
}

.MuiTableSortLabel-root {
  width: 100%;
}

.MuiInput-underline {
  border-bottom: 2px solid transparent !important;
}

.MuiInput-underline:before {
  border-bottom: 2px solid rgb(26 53 70) !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid rgb(57, 85, 105) !important;
}

label.Mui-focused {
  color: #cccccc !important;
}

.MuiButton-containedPrimary {
  color: var(--white);
  background-color: var(--main-col-op);
}

.MuiButton-containedPrimary {
  color: var(--white);
  background-color: var(--btn-dark);
}

/* .MuiButton-outlinedPrimary {
    color: rgb(21 47 66) !important;
    border: 1px solid rgb(21 47 66) !important;
	box-shadow: 0 0 0 0 !important;
} */
.MuiButton-outlinedPrimary:hover {
  color: var(--sidebar-bg-col) !important;
  border: 1px solid var(--sidebar-bg-col) !important;
  background-color: var(--btn-withe-hover) !important;
}

.MuiButton-outlinedPrimary {
  box-shadow: 0 0 0 0 !important;
}

.MuiTabs-indicator {
  background-color: var(--main-col) !important;
}

.MuiTab-textColorPrimary {
  color: var(--sidebar-bg-col);
}

.tabBar_active__NVu0m.Mui-selected {
  color: var(--main-col) !important;
}

/* .MuiGrid-root .MuiButton-containedPrimary {
    color: var(--sidebar-bg-col) !important;
    background-color: var(--btn-withe) !important;
}
.MuiGrid-root .MuiButton-containedPrimary:hover{
	background-color: var(--btn-withe-hover) !important;
} */
.BTNbar button {
  background: var(--white);
  color: var(--sidebar-bg-col) !important;
}

.BTNbar button:hover {
  background: rgba(255, 255, 255, 0.5) !important;
  color: var(--white) !important;
}

svg.trace_mainIco__2PE3g {
  stroke: var(--sidebar-bg-col) !important;
}

.table_invoiceHead__ZS50o .table_inp__1Bwa6 label {
  color: var(--sidebar-bg-col) !important;
}

tr td .MuiGrid-item button {
  background: var(--main-col-op) !important;
}

tr td .MuiGrid-item button[title="Usuń"] {
  background: var(--err-col) !important;
}

.table_tableBottom__3u0-T {
  height: 40px;
}

* {
  outline: none !important;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
  border-color: #142f42 !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: rgb(237 60 34) !important;
  opacity: 1;
}

.table_tachoiconsParent__rAx1A button {
  background: transparent !important;
}

.MuiTypography-colorTextSecondary {
  color: var(--sidebar-bg-col) !important;
}

.table_tableBottom__3u0-T .MuiButton-containedSecondary {
  background-color: rgb(255 255 255 / 100%);
  color: var(--sidebar-bg-col);
}

.table_tableBottom__3u0-T .MuiButton-containedSecondary:hover {
  background-color: rgb(255 255 255 / 30%);
}

.table_tableBottom__3u0-T .MuiButton-outlinedPrimary {
  background-color: rgb(255 255 255 / 0%);
}

.table_tableBottom__3u0-T .MuiButton-outlinedPrimary:hover {
  background-color: rgb(255 255 255 / 50%) !important;
  border: 1px solid var(--white) !important;
  color: var(--white) !important;
}

table thead th:hover span,
table thead th:hover span svg {
  color: var(--main-white) !important;
  opacity: 0.5 !important;
}

/* table button.btnStyles_primary__VHz_c {
  background-color: var(--main-col-op);
} */

html.darkTheme button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  background: #26495f;
  color: var(--white);
}

html.darkTheme button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary:hover {
  background: #203e50 !important;
  color: var(--white) !important;
}

html.darkTheme .MuiButtonBase-root.MuiIconButton-root {
  background: transparent;
}

html.darkTheme .MuiTabs-flexContainer button {
  background: transparent !important;
}

html.darkTheme .topbar_topbar__12_EL {
  background-color: #182f3d;
}

html.darkTheme .select>div {
  background: #0f161a !important;
}

html.darkTheme .css-1uccc91-singleValue {
  color: #ccc;
}

html.darkTheme svg {
  color: var(--white) !important;
}

html.darkTheme .trace_primary__1WTgc {
  stroke: #34586f;
}

html.darkTheme svg.trace_mainIco__2PE3g {
  stroke: #26495f !important;
}

html.darkTheme svg.input_searchIcon__q-OPT.input_clickAble__f6TeA {
  color: #ccc !important;
  stroke: #ccc !important;
}

html.darkTheme .css-1wa3eu0-placeholder {
  color: #ccc;
}

html.darkTheme .sidebar_parent__3ibmE::before {
  box-shadow: 3px 0px 10px #00000033;
}

html.darkTheme .sidebar_callapse__FGr_r {
  background-color: #050c106e;
}

html.darkTheme div.sidebar_itemOpen__1OzwG {
  background-color: var(--err-col) !important;
}

html.lightTheme div.sidebar_itemOpen__1OzwG,
div.sidebar_itemOpen__1OzwG {
  background-color: #010202 !important;
}

html.darkTheme .table_table__3j3_W tr td button[title="Usuń"] svg,
html.darkTheme .table_table__3j3_W tr td button[title="Usuń magazyn"] svg,
html.darkTheme .table_MuiTableRow-root__2JLaI tr button[title="Usuń"] svg,
html.darkTheme .table_MuiTableRow-root__2JLaI tr button[title="Usuń magazyn"] svg {
  color: var(--err-col);
  stroke: var(--err-col);
}

html.darkTheme .BTNbar button svg {
  stroke: #050c10;
}

html.darkTheme .MuiPaper-root {
  color: #ccc;
}

html.darkTheme button svg.button_BTNadd__1oMWU {
  color: var(--cor-col) !important;
  stroke: var(--cor-col) !important;
}

html.darkTheme button svg.button_BTNdelete__2nwdH {
  color: var(--main-col) !important;
  stroke: var(--err-col) !important;
}

html.darkTheme span.newOrEdit_outBtnRed__-VHHk,
html.darkTheme span.newOrEdit_outBtnRed__-VHHk svg,
html.darkTheme button.newOrEdit_outBtnRed__-VHHk,
html.darkTheme button.newOrEdit_outBtnRed__-VHHk svg {
  border-color: var(--main-col) !important;
  color: var(--main-col) !important;
}

html.darkTheme input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

html.darkTheme .MuiDialog-container button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  background: var(--white);
  color: #26495f;
}

html.darkTheme .MuiDialog-container button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary:hover {
  background: var(--white) !important;
  color: #26495f !important;
}

html.darkTheme .MuiDialog-container .MuiDialogContent-root .select>div {
  background: #fdfeff !important;
}

html.darkTheme .MuiDialog-container .MuiDialogContent-root .input_input__mMHik,
html.darkTheme .MuiDialog-container .MuiDialogContent-root input,
html.darkTheme .MuiDialog-container .MuiDialogContent-root .select>div {
  /* border: 1px solid var(--input-bor); */
  color: #26495f !important;
  background-color: #fdfeff !important;
}

html.darkTheme .MuiDialog-container .MuiDialogContent-root .newOrEdit_inp__1v-PL label,
html.darkTheme .MuiDialog-container .MuiDialogContent-root label {
  color: #26495f;
}

html.darkTheme .MuiDialog-container .MuiDialogContent-root .newOrEdit_select__1-ryC>div {
  color: #26495f;
  background-color: #fdfeff !important;
}

html.darkTheme .MuiDialog-container .MuiDialogContent-root svg {
  color: #26495f !important;
  stroke: #26495f !important;
}

.table_tableBottom__3u0-T {
  margin-bottom: -2px !important;
}

/* .onlyFullVersion{
	filter: saturate(0)
} */
.onlyFullVersion {
  opacity: 0.45;
}

.none .MuiCircularProgress-root,
.open .button-icon {
  display: none;
}

.open .MuiCircularProgress-root,
.none .button-icon {
  display: flex;
}

#fullVersion .MuiTooltip-tooltip {
  background: var(--err-col);
}

input.TXTinput,
textarea {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  padding: 11px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  box-sizing: border-box;
  transition: all 0.3s ease;
  height: 38px;
}

.MuiTooltip-popper>div {
  margin-top: 0;
  /* background: var(--err-col); */
}

.user_s {
  display: block;
}

.user_full {
  display: none;
}

.set_user_s_on {
  display: block;
}

.set_user_s_off {
  display: none;
}

textarea {
  border: 1px solid var(--input-bor) !important;
  border-radius: 5px !important;
  padding: 11px !important;
  font-size: var(--fontSize) !important;
  color: var(--text-col) !important;
  background-color: var(--input-bg) !important;
  box-sizing: border-box !important;
  transition: all 0.3s ease !important;
  height: auto;
}

.discription_textarea .MuiInput-underline::after,
.discription_textarea .MuiInput-underline::before {
  display: none;
}

.circularProgressMini_title {
  width: 20px !important;
  height: 20px !important;
  position: relative;
  right: 10px;
}

.driver_title {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.driver_title>div:last-child {
  width: 200px;
}

.Mui-error textarea {
  border: 1px solid var(--err-col) !important;
}

.timer-refresh {
  position: relative;
  top: 7px;
  text-align: right;
  right: -35px;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  align-items: center;
}

.onlyFullVersion-disable {
  display: none !important;
}

.sign_s {
  padding: 0;
  width: 18px;
  background: var(--err-col);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 700;
  border-radius: 3px;
}

.sign_s {
  position: absolute;
  right: 2px;
}

.slidebar>div,
.slidebar>a {
  padding-left: 10px;
  padding-right: 10px;
}


.status-history {
  background: #f9f9f9;
  border-radius: 4px;
  padding: 0 !important;
}

.status-history table tr th {
  background: #ececec !important;
}

.status-history table tr td,
.status-history table tr th {
  padding: 4px 12px !important;
}

.status-information * {
  font-size: 12px;
}

.status-information {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.status-information p {
  background-color: var(--main-col);
  color: var(--white);
  padding: 0 5px;
}

.status-information .seperator {
  min-width: 20px;
}

.status-information-grid {
  width: 100% !important;
  margin: auto !important;
}

.multi_selector>div>div:last-child {
  opacity: 0;
}

.mapPPP {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 25px;
}

.mapPPP canvas {
  width: 100% !important;
  margin: 0 auto 0 auto;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
}

.adressSearch label {
  display: none;
}

.adressSearch input {
  height: 38px !important;
}

.isTransportSetSeted {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--bg_green);
}

.map-container {
  height: 40vh;
}

.CiclicDialogSetting .error>input,
.CiclicDialogSetting .error>div {
  border-color: red !important;
}

.fileBtn>span>button {
  padding: 0;
  width: 28px !important;
  min-width: unset !important;
  position: relative;
  left: 14px;
}

.fileBtn>span {
  justify-content: space-between;
}

.fileBtn {
  width: 100%;
  min-height: 36px;
}

label[for="raised-button-file"]>div>span {
  font-size: 12px;
  text-transform: none;
}

.col7 {
  padding: 8px;
}

.col7 .MuiGrid-grid-xs-1 {
  width: 100% !important;
  max-width: unset !important;
  flex-basis: 14.28571%;
}

.col2 .MuiGrid-grid-xs-11 {
  flex-basis: 85.8%;
}

.col2 .MuiGrid-grid-xs-1 {
  flex-basis: 14.333333%;
  max-width: 14.135316%;
  padding-left: 0;
}

.col2 label[for="raised-button-file"]>div>span {
  font-size: 12px;
  color: #000;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr 34px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}

.col2 label[for="raised-button-file"]>div>span svg {
  width: 18px;
}

.col2 .MuiGrid-grid-xs-1 {
  padding-left: 0 !important;
}

.modalLegend {
  position: fixed;
  right: 0;
  left: auto;
  background: var(--white);
  padding: 25px;
  border-radius: 0;
  top: 0;
  bottom: auto;
  margin: auto 0;
  height: 100%;
}

.tabcell {
  width: 100% !important;
  max-width: unset !important;
  flex-basis: 14.28571%;
  padding: 8px !important;
}

td.tabcell {
  font-size: 12px;
}

.selectInTableCell svg {
  height: 16px;
}

.selectInTableCell * {
  max-height: 28px !important;
  min-height: unset !important;
  padding: 0 !important;
}

.selectInTableCell>div {
  padding: 0 2px 0 6px !important;
  font-size: 11px !important;
}

/* paginacja w modalu alternatywna wersja ".modalTabPagin"*/
.modalTabPagin {
  border-radius: 5px;
  margin-top: 6px;
  padding: 0 4px !important;
}

.modalTabPagin button.MuiButton-containedSecondary {
  background-color: var(--bg_green);
  border-color: var(--bg_green);
}

.modalTabPagin button:hover {
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  color: #000
}

.modalTabPagin button.MuiButton-root {
  min-width: 45px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
  font-size: 13px;
}

.loading.hide {
  display: none;
}

.iframe {
  /* iframe styles */
}

.iframe.hide {
  display: none;
}

button[state="on"] {
  background: rgb(255 255 255 / 25%);
  max-height: 27.5px;
}

button[state="on"]:hover,
button[state="off"]:hover {
  background: rgb(255 255 255 / 15%) !important;
  opacity: 1 !important;
  color: var(--white) !important;
  border-color: var(--white) !important;
}

.MuiDialog-paperWidthXl {
  height: 100%;
}

.comunicator {
  background: var(--gradientNewsYellow);
}

.load {
  background: var(--gradientNewsGreen);
}

.unload {
  background: var(--gradientNewsBlue);
}