.tableCarList {
    float: left;
    width: 350px;
    height: 100%;
    max-height: 100%;
    background-color: var(--light);
    overflow-y: auto;
}

.root {
    width: '100%';
}

.container {
    max-height: calc(100vh - 88px);
    /* overflow: hidden; */
}

.container::-webkit-scrollbar {
    width: 16px;
    height: 12px;
    -webkit-appearance: none;
}

.container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .25);
    border: 4px solid transparent;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    background-clip: content-box;
}

.container::-webkit-scrollbar-track-piece {
    background: rgb(20 47 66 / 7%);
}

.container::-webkit-scrollbar-corner {
    background: transparent;
}



.btn {
    cursor: pointer;
}

.icon {
    color: var(--main-col);
}

.icon:hover {
    color: var(--main-col-op);
}

.iconBtn {
    color: var(--main-col);
    cursor: pointer;
}

.iconBtn:hover {
    color: var(--main-col-op);
    cursor: pointer;
}

.iconBtnShowPoint {
    color: #000;
    cursor: pointer;
    margin-top: 7px;
}

.iconBtnShowPoint:hover {
    color: #666;
    cursor: pointer;
}

.iconBtnOrder {
    color: var(--main-col);
    cursor: pointer;
    padding-top: 4px;
}

.iconBtnOrder:hover {
    color: var(--main-col-op);
    cursor: pointer;
}

.poperBg {
    background-color: var(--bg);
    padding: 4px;
    border: 1px solid var(--light);
    font-size: 10px;
}

.poperBgOrderSummary {
    background-color: #f4f4f4;
    padding: 4px;
    border: 1px solid #ccc;
    font-size: 10px;
    height: 430px;
    width: 240px;
    overflow: auto;
}






.popupLabel {
    font-weight: bold;
}

.time_red {
    color: red;
}

.time_green {
    color: green;
}

.time_yellow {
    color: #ffc40c;
}


.accordion_details {
    display: block !important;
}

.address {
    width: 190px;
    margin-left: 3px;
}

.header {
    margin-left: 8px;
    margin-top: 2px;
}

.headerBold {
    font-weight: bold;
}

.orderNo {
    font-size: var(--fontSize);
    font-weight: bold;
    padding: 4px;
}

.btnShowRoute {
    width: 100%;
}

.btnCenterRoute {
    width: 10%;
}

.iconBtnShowCenterRoute {
    color: #000;
    cursor: pointer;
    margin-top: 0px;
    margin-left: 8px;
}

.iconBtnShowCenterRoute:hover {
    color: var(--white);
    cursor: pointer;
}

.btnCloseRoute {
    float: right;
    cursor: pointer;
    margin-top: -4px;
}

.mapListPover {
    left: 0px !important;
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
    transform: translate3d(246px, 33vh, 0px) !important;
}

.mapListMiniPover {
    margin-top: 130px !important;
    left: 117px !important;
}

/* .searchBar .MuiFormControl-root.MuiTextField-root{
    background-color: var(--white);
    height: 30px;
    padding-left: 30px;
} */

.searchBar .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    background: var(--white);
    border: 1px solid #282d4047;
    border-radius: 5px;
}

.tableCarList tbody td {
    font-size: .75rem;
    padding: 10px 15px;
}

.fuelBox {
    min-width: 75px;
    display: grid;
    grid-template-columns: 1fr 27px;
    grid-template-rows: 1fr;
    gap: 0px 8px;
    grid-template-areas: ". .";
    text-align: right;
    align-items: center;
    justify-items: end;
    font-size: 11px;
    font-weight: 500;
}

.btnToFuel,
.btnToFuelWarning {
    padding: 2px 2px;
    display: flex;
    background: var(--white);
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -3px;
    margin-bottom: -3px;
}

.fuelVals {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.btnToFuel:hover,
.btnToFuelWarning:hover {
    background: rgb(246, 246, 246);
}

.btnToFuel svg,
.btnToFuelWarning svg {
    margin: 0 !important;
    width: 22px;
    height: 20px;
}

.btnToFuel svg {
    fill: #707070;
}

.btnToFuelWarning svg {
    fill: #f38400;
}



.fuleProcent {
    width: 3px;
    height: 21px;
    display: block;
    /* border: 1px solid #e8e8e8; */
    margin: -2px 0;
    box-shadow: inset 0 0 0 3px rgb(0 0 0 / 2%), 0 0 0 1px rgb(0 0 0 / 10%);
    border-radius: 10px;
}