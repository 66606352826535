.buttonBox {
    display: flex;
    gap: 5px;
  }

.header{
    padding: 8px 17px !important
  }

.dialogHead > h2 {
    display: flex;
    align-items: center;
  }

button.closeButton {
    justify-self: flex-end;
    transform: translate(20px, -10px);
    margin-left: auto;
  }

.row{
  padding: 10px !important
}
.rowShort{
  padding: 10px !important;
  width: 100px !important;
}

div.WOHead.secondClass {
  padding: 0 8px 8px;
}

div.WOHead.iconsAddRemove.secondClass {
  display: flex;
  align-items: flex-end;
  padding-bottom: 12px;
}
div.select {
  max-height: 38px;
}

.flexC {
  align-items: center;
  display: flex;
  justify-content: center;
}

.select > div {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
  /* max-height: 35px; */
}

.select.error > div {
  border-color: var(--err-col);
}