div.parent {
  width: 100%;
  height: calc(100vh - 88px);
  margin: 0;
}

div.parent > div.noPad {
  padding: 0;
  height: 100%;
  overflow-y: auto;
}

.headText {
  margin-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--text-col);
}

.headText span {
  margin-left: 100px;
}

.text {
  font-family: Poppins;
  font-size: var(--fontSize);
  line-height: 40px;
  letter-spacing: 0;
  /* color: */
}

.text span {
  font-size: var(--fontSize);
  line-height: 24px;
  color: var(--text-col);
}

.chatText {
  font-size: var(--fontSize);
  line-height: 20px;
  color: var(--text-col);
}

.side {
  background-color: var(--topbar-col);
  height: 100%;
  padding: 0;
}

.dotGreen,
.dotRed {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.dotGreen {
  background-color: var(--cor-col);
}

.dotRed {
  background-color: var(--err-col);
}

.container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 134px);
  justify-content: flex-end;
  align-items: flex-start;
}

.bubbleContainer {
  margin-left: 30px;
  max-width: 75%;
  /* width: 100%; */
  /* display: flex;
  flex-direction: column; */
}

.bubbleContainer.right {
  align-self: flex-end;
  margin-right: 30px;
}

.headContainer {
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.bubble {
  border: 0px solid var(--input-bor);
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  display: inline-block;
}

.right {
  justify-content: flex-end;
}

button.icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 5px 5px;
}

button.send {
  width: 44px;
  height: 44px;
  border-radius: 25px;
  margin: 5px 5px;
  background-color: var(--main-col-op);
  display: flex;
  align-items: center;
  justify-content: center;
}

button.you {
  background-color: var(--main-col-op);
}

button.me {
  background-color: var(--cor-col);
}

button.me span,
button.you span,
button.send span {
  color: var(--white);
}

button.me:hover span,
button.you:hover span,
button.send:hover span {
  color: var(--main-col-op);
}

.chatInputParent {
  display: flex;
  margin: 50px 20px 10px;
  color: var(--text-col);
  font-size: 14px;
  align-items: center;
}

.chatInputParent span.sendText {
  min-width: 180px;
}

span.sendText {
  min-width: 180px;
}

.listBtn {
  background-color: var(--btn-dark) !important;
  width: 20px;
  height: 20px;
  min-width: unset !important;
  padding: 3px !important;
  border-radius: 100px !important;
  margin-right: 5px !important;
}
.listBtn:hover {
  background-color: var(--main-col-hover) !important;
}
.listBtn * {
  color: #fff;
}

.tableList {
  width: 100%;
  max-width: 600px;
  max-height: 50vh;
  overflow-x: hidden !important;
}
.tableBox {
  overflow-x: hidden !important;
}

.enterCheckbox {
  display: flex;
  justify-content: flex-end;
  margin-right: 120px;
  margin-top: -9px;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
}
