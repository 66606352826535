.mapPointsModal{
    background-color: rgb(230, 230, 230);
}
.columns{
    display: grid;
    grid-template-columns: 75% 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas: ". .";
}
.mapPointsList{
    font-size: 11px;
    padding: 10px;
}

.mapPointsList *{
    cursor: context-menu;   
}

.mapPointsList ul{
    list-style: none;
}
.mapPointsList li{
    margin-bottom: 6px;
    background: var(--white);
    padding: 2px 5px;
    border-radius: 4px;
}

.mapPointsList li[draggable="true"] *{
    cursor: pointer;
}
.mapPointsList li[draggable="true"]:hover{
    opacity: .85;
}

.mapPointsList h3{
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;
    padding-bottom: 6px;
    border-bottom: 1px solid #b2b2b2;
}
.mapPointsList p{
    display: grid;
    grid-template-columns: 15px 1fr 15px;
    grid-template-rows: 1fr;
    grid-gap: 5px;
    gap: 5px;
    grid-template-areas: ". . .";
}
.mapPointsList p span {
    display: flex;
    align-items: center;
}
.mapPointsList svg{
    max-width: 15px;
    max-height: 18px;
}

.mapPointsList li {
    opacity: .2;
}
.mapPointsList li[draggable="true"] ~ li, li[draggable="true"] {
    opacity: 1;
}

li.on{
    opacity: 1 !important;
}
li.off{
    opacity: .2 !important;
}