.parent {
  width: 200px;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    var(--sidebar-bg-col),
    var(--third-col)
  );
  opacity: 1;
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.parent::before {
  content: " ";
  width: 200px;
  min-height: 100%;
  height: max-content;
  position: fixed;
  top: 0;
  left: 0;
  background-image: var(--slidebar_bg);
  box-shadow: 0px 3px 6px #00000029;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: 35%; */
  background-position: center;
  background-blend-mode: multiply;
  opacity: 1;
}

.logotxt {
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--white);
  text-align: center;
  border-bottom: 1px solid var(--white);
  padding-bottom: 6px;
  margin-bottom: 15px;
}

div.text span {
  color: var(--white);
  font-size: var(--fontSize);
  font-weight: 300;
}

a.nestedText span {
  color: var(--white);
  font-size: var(--fontSize_min);
  font-weight: 300;
  padding-left: 12px;
}

a.nestedText {
  height: 30px;
  border-bottom: 1px solid var(--sidebar-bg-col);
  display: flex;
}
a.nestedText:last-child {
  border-bottom: 0;
}

a.nestedText:hover {
  background-color: var(--sidebar-bg-col);
}

svg.whiteFill {
  fill: var(--white);
}

div.icon {
  color: var(--white);
  display: inline-flex;
  margin-right: 10px;
  width: 18px;
  flex-shrink: 0;
  opacity: 0.5;
  min-width: auto;
}

.callapse {
  background-color: var(--sidebar-open);
}

div.itemOpen {
  background-color: var(--sidebar-open);
}

div.itemOpen:hover {
  background-color: var(--sidebar-hover);
}

div.version {
  font-size: 10px;
  margin-bottom: 0;
  color: #4b575f;
  position: relative;
  z-index: 9;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.version_with_promo {
  font-size: 10px;
  margin-bottom: 0;
  color: #4b575f;
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 170px;
  gap: 10px;
  padding-top: 8px;
}

.hidden {
  visibility: hidden;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2vh 0;
}

.logo img {
  width: 115px;
}

.slidebar > div {
  padding-left: 0px !important;
  padding-right: 0px;
}

.navBox {
  overflow: auto;
  display: block;
  height: calc(100vh - 250px) !important;
  margin-bottom: 20px;
  padding-right: 0 !important;
}

.navBox::-webkit-scrollbar {
  width: 10px;
}

.navBox::-webkit-scrollbar-track {
  background: var(--main-col-op);
}

.navBox::-webkit-scrollbar-thumb {
  background: var(--btn-light);
}

.navBox::-webkit-scrollbar-thumb:hover {
  background: var(--btn-light-hover);
}

.navBox_with_promo {
  overflow: auto;
  display: block;
  height: calc(100vh - 285px) !important;
}

.navBox_with_promo::-webkit-scrollbar {
  display: none;
}

.navBox_with_promo {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.version > div {
  background: transparent !important;
  font-weight: 300;
  gap: 2px;
  letter-spacing: 0.1px;
  color: #dadcdd !important;
}

.version > div button {
  background: var(--cor-col);
  color: var(--btn-withe);
}
