button.btn {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  z-index: 10;
}

button.err:hover {
  background-color: var(--err-col-bg);
}

button.car:hover,
button.primary:hover {
  background-color: var(--main-col-op);
}

.btn svg {
  width: 14px;
  height: 14px;
  stroke: var(--btn-stroke);
}

button.err {
  background-color: var(--err-col);
}

button.check {
  background-color: var(--cor-col);
}

button.check:hover {
  background-color: var(--cor-col);
}

button.errDisabled {
  background-color: var(--main-disabled);
}

button.primary {
  background-color: var(--main-col);
}

table button.primary {
  /* background-color: var(--main-col); */
  background-color: var(--main-to-op);
}

button.car {
  color: var(--white);
  stroke-width: 0;
  background-color: var(--main-col);
}

button.car span {
  color: inherit;
}
button.copy {
  background-color: var(--sidebar-bg-col);
}
button.edit {
  background-color: var(--sidebar-bg-col);
}
button.delete {
  background-color: var(--main-col);
}
button.deleteDisabled {
  background-color: var(--lgray2);
  /* opacity: 50% */
}

button.copy:hover {
  background-color: var(--sidebar-hover);
}
button.edit:hover {
  background-color: var(--sidebar-hover);
}
button.delete:hover {
  background-color: var(--main-col-hover);
}

button.BtnLabel {
  background-color: var(--btn-dark);
  color: var(--white);
  font-size: 10px;
  padding: 0 8px;
  margin: 0 !important;
}
button.BtnLabel:hover {
  background-color: var(--btn-light);
}
button.BtnLabel > span.MuiButton-label {
  display: flex !important;
  gap: 3px !important;
}
button.BtnLabel span svg {
  width: 15px;
  margin-right: 3px;
}
