:root{
	--width-max: 258px;
}

.searchAndPlanContainer{
    width: 280px;
    background-color: #FFF;
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 27%);
    padding: 4px;
    text-align: center;
    z-index: 10;
}

.inputClass{
    width: var(--width-max);
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 7px;
    display: inline-block;
    font-size: 12px;/*var(--headSize);*/
    margin-bottom: 4px;
}

.closestVehicleContainer{
    width: 270px;
    background-color: #FFF;
    padding: 2px;
    font-size: 9px;
    max-height: 400px;
    overflow-y: auto;
}

.formControlLabel{
    font-size: 10px;
}

.radio {
    width: 8px;
    height: 8px;
    margin-left: 15px !important;
    margin-right: 4px !important;
}

.radio svg{
    width: 19px;
    height: 19px;
}


.btn{
    width: 270px;
    margin-bottom: 2px !important;
}

.iconBtnShowPoint{
    color: #000;
    cursor: pointer;
    float: left;
}
.iconBtnShowPoint:hover{
    color: #666;
    cursor: pointer;
}

.header{
    margin-left: 0px;
    margin-top: 2px;
    font-weight: bold;
    float: left;
    text-align: left;
}

.tableMatrix{
    border-collapse: collapse;
}

.tableMatrix thead tr td{
    font-weight: bold;
    background-color: rgba(88, 103, 221, 1);
    color: #fff;
    padding: 5px;
}

.tableMatrix tr td{
    border-bottom: 1px solid #ccc;
    padding: 3px;
}

.tableMatrix tr:nth-child(even) {
    background-color: #f2f2f2;
}