@font-face {
	font-family: Poppins;
	src: url('./Poppins-ExtraLight.ttf') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url('./Poppins-Light.ttf') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url('./Poppins-Regular.ttf') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url('./Poppins-Medium.ttf') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url('./Poppins-SemiBold.ttf') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url('./Poppins-Bold.ttf') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: Poppins;
	src: url('./Poppins-Black.ttf') format('woff');
	font-weight: 900;
	font-style: normal;
}