form.traceBox {
  border: 1px solid var(--light);
  border-radius: 5px;
  display: flex;
  margin: 9px 20px;
  /* height: 140px; */
  flex-direction: row;
  background-color: var(--bg_plus);
  position: relative;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 5%);
}

form.traceBox.sm {
  min-height: 100px;
}

.tBox {
  padding: 15px 0 25px;
}

.moveBox {
  width: 14px;
  background-color: var(--light);
}

.moveIcon {
  stroke: var(--light);
  width: 18px;
  height: 18px;
  margin: 4px;
}

svg.mainIco {
  stroke: var(--main-col);
  width: 40px;
  height: 100%;
  align-self: center;
  margin: 0 20px;
  position: relative;
  left: -2px;
}

svg.matMainIco {
  color: var(--main-col);
  width: 37px;
  height: 40px;
  align-self: center;
  margin: 20px;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 10px 0;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-direction: row;
}

.inp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.inp h6 {
  font-size: 14px;
  color: var(--main-col);
  font-weight: 500;
}

.inp label {
  font-size: var(--fontSize);
  font-weight: 500;
  color: var(--text-col);
}

.input {
  border: 1px solid var(--input-bor);
  border-radius: 5px;
  padding: 4px;
  font-size: var(--fontSize);
  color: var(--text-col);
  background-color: var(--input-bg);
}

.input:focus {
  border: 1px solid var(--main-col);
  outline-color: var(--main-col);
}

.check {
  margin: 8px 8px 8px 0;
  position: relative;
}

.checkPar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.checkPar label {
  font-size: var(--fontSize);
  color: var(--text-col);
  font-weight: 500;
  margin: 3px;
}

.remove {
  stroke: var(--err-col-op);
  transition: all 0.3s ease;
}

.remove:hover {
  stroke: var(--err-col);
}

.primary {
  stroke: var(--main-col-op);
  transition: all 0.3s ease;
}

.primary:hover {
  stroke: var(--main-col);
}

.corner {
  position: absolute;
  right: 5px;
  top: 5px;
}

.lowerCorner {
  position: absolute;
  right: 8px;
  bottom: 21px;
}

.lowerCorner button {
  padding: 3px;
}

.clientAddParent {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 5px;
  top: 2px;
}

button.btnTraceIcon {
  width: 18px;
  height: 18px;
  padding: 0;
}

.ValueContainer {
  height: 100%;
}

label.longLabel {
  font-size: 8px;
}

div.traceTxtParent {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
  position: relative;
  top: 3px;
}

.traceTxt {
  color: var(--text-col);
  font-size: 11px;
  display: flex;
  gap: 10px;
}

.traceTxt strong {
  font-weight: 700;
  margin-left: 10px;
}

.absoluteCheckboxGrid {
  position: absolute;
  left: auto;
  top: 30px;
  right: 0px;
  min-width: 151px;
}

.traceTxt strong:first-child {
  margin-left: 0;
}

button.btnIcon {
  padding: 3px;
}

button.btnCopyTxt {
  padding: 0px;
  transform: scale(0.7);
  position: relative;
  top: 2px;
}

.add {
  stroke: var(--cor-col);
}

.remove {
  stroke: var(--err-col);
}

.error {
  background-color: var(--cor-green-light) !important;
  border-color: var(--cor-green-light-stroke) !important;
}

div.tBox div.btnCargoBox {
  display: flex;
  align-items: flex-end;
  padding: 2px 8px;
  position: absolute;
  transform: translateY(-5px);
}

div.tBox div.dense {
  padding: 2px 8px;
}

.inp.withIcon {
  width: calc(100% - 80px);
  margin-left: auto;
}

.manualAdress {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-top: 9px;
}

.manualAdress label {
  font-size: 10px;
  font-weight: 500;
  color: var(--text-col);
}

.manualAdress .check {
  margin-left: 0;
}

.newRow {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.newRow > div:last-child {
  width: 100%;
}

.input:disabled {
  background-color: #eeeeee;
}

.flexRow {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}

.traceBtn > span:first-child,
.traceBtnGreen > span:first-child {
  font-size: 10px;
  color: #fff;
  display: flex;
  gap: 5px;
  text-transform: none;
  font-weight: 400;
}

button.traceBtn,
button.traceBtnGreen {
  margin-top: 16px !important;
  padding: 0px 8px !important;
  display: flex;
  justify-content: flex-start;
  min-width: unset !important;
  max-width: 145px;
}

button.traceBtn {
  background: var(--sidebar-bg-col) !important;
}

button.traceBtnGreen {
  background: var(--cor-col) !important;
}
