.react-calendar-timeline * {
	font-size:var(--fontSize);
  box-sizing: border-box; }

.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap; }

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none; }

.react-calendar-timeline .rct-item:hover {
  z-index: 88; }

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%; }

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
}
  .react-calendar-timeline .rct-sidebar.rct-sidebar-right {
    border-right: 0;
	}
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px 0 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    color: var(--text-col);
    margin: 0;
	}
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
      background:var(--topbar-col); }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
      background: transparent; }

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid #d0d0d022;
  z-index: 30; }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 2px; }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, 
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(237, 60, 34, 0.3); }

.react-calendar-timeline .rct-horizontal-lines {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none; }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    box-sizing: border-box;
    z-index: 40; }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: var(--topbar-col); }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: transparent; }

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  background: #2196f3;
  z-index: 51; }

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
	color:var(--text-col);
  cursor: pointer;
  font-size: 14px;

}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  color: var(--main-col-op); }

.react-calendar-timeline .rct-header-root {
  background: var(--topbar-col);
}

.react-calendar-timeline .rct-calendar-header {
	border-bottom: 1px solid var(--main-col-op)
}

.react-calendar-timeline .rctLeftHeader {
	background: var(--white);
	color:var(--main-col-op);
	display: flex;
	padding-left:35px;
	font-size:14px;
	font-weight: 500;
	border-bottom: 1px solid var(--main-col-op);
	display: flex;
	flex-direction: column;
	width: 220px;
	/* height:90px; */
}

.react-calendar-timeline .rctLeftHeaderAlt {
	background: var(--white);
	color:var(--main-col-op);
	display: flex;
	padding-left:35px;
	font-size:14px;
	font-weight: 500;
	border-bottom: 1px solid var(--main-col-op);
	display: flex;
	flex-direction: column;
	width: 350px;
	/* height:90px; */
}

.react-calendar-timeline .rctLeftLabel {
	font-size:14px;
	font-weight: 500;
}

.correctAssignment {
	background: var(--err-col)
}

.warningAssignment {
	/* background: red */
	background: repeating-linear-gradient(
  45deg,
  var(--yel),
	var(--yel) 0.5em,
	transparent 0.5em,
	transparent 1em
	);
	text-shadow: #000 0 0 5px ;
}

.badAssignment {
	background: var(--cor-col)
}

.rctSearchParent {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 160px;
	position: relative;
	box-sizing: border-box;
}

.rctSearch {

	border: 1px solid var(--input-bor);
	border-radius: 5px;
	padding: 10px;
	font-size: var(--fontSize);
	color: var(--text-col);
	background-color: var(--input-bg);
	box-sizing: border-box;

}

.rctSearch:focus {
	border: 1px solid var(--main-col);
	outline-color: var(--main-col)
}

.rctSearchIcon {
	position:absolute;
	right: 8px;
	top: 8px;
	width:20px;
	height:20px;
	stroke: var(--input-bor)
}
