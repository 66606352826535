.start {
  position: relative;
}

/* .end-pl::before {
  background-image: url(https://www.countryflags.io/pl/shiny/24.png);
  position: absolute;
  width: 24px;
  height: 24px;
  content: " ";
}

.end-de::before {
  background-image: url(https://www.countryflags.io/de/shiny/24.png);
  position: absolute;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-de::after { position: absolute;
  background-image: url("https://www.countryflags.io/de/shiny/24.png");
  position: absolute;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-pl::after { position: absolute;
  background-image: url("https://www.countryflags.io/pl/shiny/24.png");
  position: absolute;
  width: 24px;
  height: 24px;
  content: " ";
} */

.start-AF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AF::before {
  background-image: url(https://www.countryflags.io/AF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AX::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AX/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AX::before {
  background-image: url(https://www.countryflags.io/AX/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AL::before {
  background-image: url(https://www.countryflags.io/AL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-DZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/DZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-DZ::before {
  background-image: url(https://www.countryflags.io/DZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AS::before {
  background-image: url(https://www.countryflags.io/AS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AD::before {
  background-image: url(https://www.countryflags.io/AD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AO::before {
  background-image: url(https://www.countryflags.io/AO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AI::before {
  background-image: url(https://www.countryflags.io/AI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AQ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AQ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AQ::before {
  background-image: url(https://www.countryflags.io/AQ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AG::before {
  background-image: url(https://www.countryflags.io/AG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AR::before {
  background-image: url(https://www.countryflags.io/AR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AM::before {
  background-image: url(https://www.countryflags.io/AM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AW::before {
  background-image: url(https://www.countryflags.io/AW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AU::before {
  background-image: url(https://www.countryflags.io/AU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AT::before {
  background-image: url(https://www.countryflags.io/AT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AZ::before {
  background-image: url(https://www.countryflags.io/AZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BS::before {
  background-image: url(https://www.countryflags.io/BS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BH::before {
  background-image: url(https://www.countryflags.io/BH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BD::before {
  background-image: url(https://www.countryflags.io/BD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BB::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BB/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BB::before {
  background-image: url(https://www.countryflags.io/BB/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BY::before {
  background-image: url(https://www.countryflags.io/BY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BE::before {
  background-image: url(https://www.countryflags.io/BE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BZ::before {
  background-image: url(https://www.countryflags.io/BZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BJ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BJ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BJ::before {
  background-image: url(https://www.countryflags.io/BJ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BM::before {
  background-image: url(https://www.countryflags.io/BM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BT::before {
  background-image: url(https://www.countryflags.io/BT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BO::before {
  background-image: url(https://www.countryflags.io/BO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BA::before {
  background-image: url(https://www.countryflags.io/BA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BW::before {
  background-image: url(https://www.countryflags.io/BW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BV::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BV/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BV::before {
  background-image: url(https://www.countryflags.io/BV/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BR::before {
  background-image: url(https://www.countryflags.io/BR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IO::before {
  background-image: url(https://www.countryflags.io/IO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BN::before {
  background-image: url(https://www.countryflags.io/BN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BG::before {
  background-image: url(https://www.countryflags.io/BG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BF::before {
  background-image: url(https://www.countryflags.io/BF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BI::before {
  background-image: url(https://www.countryflags.io/BI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KH::before {
  background-image: url(https://www.countryflags.io/KH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CM::before {
  background-image: url(https://www.countryflags.io/CM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CA::before {
  background-image: url(https://www.countryflags.io/CA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CV::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CV/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CV::before {
  background-image: url(https://www.countryflags.io/CV/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KY::before {
  background-image: url(https://www.countryflags.io/KY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CF::before {
  background-image: url(https://www.countryflags.io/CF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TD::before {
  background-image: url(https://www.countryflags.io/TD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CL::before {
  background-image: url(https://www.countryflags.io/CL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CN::before {
  background-image: url(https://www.countryflags.io/CN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CX::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CX/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CX::before {
  background-image: url(https://www.countryflags.io/CX/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CC::before {
  background-image: url(https://www.countryflags.io/CC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CO::before {
  background-image: url(https://www.countryflags.io/CO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KM::before {
  background-image: url(https://www.countryflags.io/KM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CG::before {
  background-image: url(https://www.countryflags.io/CG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CD::before {
  background-image: url(https://www.countryflags.io/CD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CK::before {
  background-image: url(https://www.countryflags.io/CK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CR::before {
  background-image: url(https://www.countryflags.io/CR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CI::before {
  background-image: url(https://www.countryflags.io/CI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-HR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/HR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-HR::before {
  background-image: url(https://www.countryflags.io/HR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CU::before {
  background-image: url(https://www.countryflags.io/CU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CY::before {
  background-image: url(https://www.countryflags.io/CY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CZ::before {
  background-image: url(https://www.countryflags.io/CZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-DK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/DK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-DK::before {
  background-image: url(https://www.countryflags.io/DK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-DJ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/DJ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-DJ::before {
  background-image: url(https://www.countryflags.io/DJ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-DM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/DM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-DM::before {
  background-image: url(https://www.countryflags.io/DM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-DO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/DO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-DO::before {
  background-image: url(https://www.countryflags.io/DO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-EC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/EC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-EC::before {
  background-image: url(https://www.countryflags.io/EC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-EG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/EG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-EG::before {
  background-image: url(https://www.countryflags.io/EG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SV::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SV/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SV::before {
  background-image: url(https://www.countryflags.io/SV/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GQ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GQ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GQ::before {
  background-image: url(https://www.countryflags.io/GQ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ER::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ER/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ER::before {
  background-image: url(https://www.countryflags.io/ER/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-EE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/EE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-EE::before {
  background-image: url(https://www.countryflags.io/EE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ET::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ET/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ET::before {
  background-image: url(https://www.countryflags.io/ET/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-FK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/FK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-FK::before {
  background-image: url(https://www.countryflags.io/FK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-FO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/FO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-FO::before {
  background-image: url(https://www.countryflags.io/FO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-FJ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/FJ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-FJ::before {
  background-image: url(https://www.countryflags.io/FJ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-FI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/FI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-FI::before {
  background-image: url(https://www.countryflags.io/FI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-FR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/FR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-FR::before {
  background-image: url(https://www.countryflags.io/FR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GF::before {
  background-image: url(https://www.countryflags.io/GF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PF::before {
  background-image: url(https://www.countryflags.io/PF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TF::before {
  background-image: url(https://www.countryflags.io/TF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GA::before {
  background-image: url(https://www.countryflags.io/GA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GM::before {
  background-image: url(https://www.countryflags.io/GM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GE::before {
  background-image: url(https://www.countryflags.io/GE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-DE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/DE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-DE::before {
  background-image: url(https://www.countryflags.io/DE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GH::before {
  background-image: url(https://www.countryflags.io/GH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GI::before {
  background-image: url(https://www.countryflags.io/GI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GR::before {
  background-image: url(https://www.countryflags.io/GR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GL::before {
  background-image: url(https://www.countryflags.io/GL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GD::before {
  background-image: url(https://www.countryflags.io/GD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GP::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GP/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GP::before {
  background-image: url(https://www.countryflags.io/GP/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GU::before {
  background-image: url(https://www.countryflags.io/GU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GT::before {
  background-image: url(https://www.countryflags.io/GT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GG::before {
  background-image: url(https://www.countryflags.io/GG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GN::before {
  background-image: url(https://www.countryflags.io/GN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GW::before {
  background-image: url(https://www.countryflags.io/GW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GY::before {
  background-image: url(https://www.countryflags.io/GY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-HT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/HT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-HT::before {
  background-image: url(https://www.countryflags.io/HT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-HM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/HM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-HM::before {
  background-image: url(https://www.countryflags.io/HM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VA::before {
  background-image: url(https://www.countryflags.io/VA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-HN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/HN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-HN::before {
  background-image: url(https://www.countryflags.io/HN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-HK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/HK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-HK::before {
  background-image: url(https://www.countryflags.io/HK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-HU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/HU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-HU::before {
  background-image: url(https://www.countryflags.io/HU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IS::before {
  background-image: url(https://www.countryflags.io/IS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IN::before {
  background-image: url(https://www.countryflags.io/IN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ID::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ID/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ID::before {
  background-image: url(https://www.countryflags.io/ID/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IR::before {
  background-image: url(https://www.countryflags.io/IR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IQ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IQ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IQ::before {
  background-image: url(https://www.countryflags.io/IQ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IE::before {
  background-image: url(https://www.countryflags.io/IE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IM::before {
  background-image: url(https://www.countryflags.io/IM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IL::before {
  background-image: url(https://www.countryflags.io/IL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-IT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/IT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-IT::before {
  background-image: url(https://www.countryflags.io/IT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-JM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/JM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-JM::before {
  background-image: url(https://www.countryflags.io/JM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-JP::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/JP/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-JP::before {
  background-image: url(https://www.countryflags.io/JP/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-JE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/JE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-JE::before {
  background-image: url(https://www.countryflags.io/JE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-JO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/JO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-JO::before {
  background-image: url(https://www.countryflags.io/JO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KZ::before {
  background-image: url(https://www.countryflags.io/KZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KE::before {
  background-image: url(https://www.countryflags.io/KE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KI::before {
  background-image: url(https://www.countryflags.io/KI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KR::before {
  background-image: url(https://www.countryflags.io/KR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KW::before {
  background-image: url(https://www.countryflags.io/KW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KG::before {
  background-image: url(https://www.countryflags.io/KG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LA::before {
  background-image: url(https://www.countryflags.io/LA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LV::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LV/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LV::before {
  background-image: url(https://www.countryflags.io/LV/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LB::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LB/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LB::before {
  background-image: url(https://www.countryflags.io/LB/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LS::before {
  background-image: url(https://www.countryflags.io/LS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LR::before {
  background-image: url(https://www.countryflags.io/LR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LY::before {
  background-image: url(https://www.countryflags.io/LY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LI::before {
  background-image: url(https://www.countryflags.io/LI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LT::before {
  background-image: url(https://www.countryflags.io/LT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LU::before {
  background-image: url(https://www.countryflags.io/LU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MO::before {
  background-image: url(https://www.countryflags.io/MO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MK::before {
  background-image: url(https://www.countryflags.io/MK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MG::before {
  background-image: url(https://www.countryflags.io/MG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MW::before {
  background-image: url(https://www.countryflags.io/MW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MY::before {
  background-image: url(https://www.countryflags.io/MY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MV::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MV/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MV::before {
  background-image: url(https://www.countryflags.io/MV/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ML::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ML/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ML::before {
  background-image: url(https://www.countryflags.io/ML/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MT::before {
  background-image: url(https://www.countryflags.io/MT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MH::before {
  background-image: url(https://www.countryflags.io/MH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MQ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MQ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MQ::before {
  background-image: url(https://www.countryflags.io/MQ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MR::before {
  background-image: url(https://www.countryflags.io/MR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MU::before {
  background-image: url(https://www.countryflags.io/MU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-YT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/YT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-YT::before {
  background-image: url(https://www.countryflags.io/YT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MX::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MX/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MX::before {
  background-image: url(https://www.countryflags.io/MX/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-FM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/FM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-FM::before {
  background-image: url(https://www.countryflags.io/FM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MD::before {
  background-image: url(https://www.countryflags.io/MD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MC::before {
  background-image: url(https://www.countryflags.io/MC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MN::before {
  background-image: url(https://www.countryflags.io/MN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ME::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ME/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ME::before {
  background-image: url(https://www.countryflags.io/ME/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MS::before {
  background-image: url(https://www.countryflags.io/MS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MA::before {
  background-image: url(https://www.countryflags.io/MA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MZ::before {
  background-image: url(https://www.countryflags.io/MZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MM::before {
  background-image: url(https://www.countryflags.io/MM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NA::before {
  background-image: url(https://www.countryflags.io/NA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NR::before {
  background-image: url(https://www.countryflags.io/NR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NP::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NP/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NP::before {
  background-image: url(https://www.countryflags.io/NP/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NL::before {
  background-image: url(https://www.countryflags.io/NL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AN::before {
  background-image: url(https://www.countryflags.io/AN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NC::before {
  background-image: url(https://www.countryflags.io/NC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NZ::before {
  background-image: url(https://www.countryflags.io/NZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NI::before {
  background-image: url(https://www.countryflags.io/NI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NE::before {
  background-image: url(https://www.countryflags.io/NE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NG::before {
  background-image: url(https://www.countryflags.io/NG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NU::before {
  background-image: url(https://www.countryflags.io/NU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NF::before {
  background-image: url(https://www.countryflags.io/NF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MP::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MP/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MP::before {
  background-image: url(https://www.countryflags.io/MP/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-NO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/NO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-NO::before {
  background-image: url(https://www.countryflags.io/NO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-OM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/OM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-OM::before {
  background-image: url(https://www.countryflags.io/OM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PK::before {
  background-image: url(https://www.countryflags.io/PK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PW::before {
  background-image: url(https://www.countryflags.io/PW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PS::before {
  background-image: url(https://www.countryflags.io/PS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PA::before {
  background-image: url(https://www.countryflags.io/PA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PG::before {
  background-image: url(https://www.countryflags.io/PG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PY::before {
  background-image: url(https://www.countryflags.io/PY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PE::before {
  background-image: url(https://www.countryflags.io/PE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PH::before {
  background-image: url(https://www.countryflags.io/PH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PN::before {
  background-image: url(https://www.countryflags.io/PN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PL::before {
  background-image: url(https://www.countryflags.io/PL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PT::before {
  background-image: url(https://www.countryflags.io/PT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PR::before {
  background-image: url(https://www.countryflags.io/PR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-QA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/QA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-QA::before {
  background-image: url(https://www.countryflags.io/QA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-RE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/RE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-RE::before {
  background-image: url(https://www.countryflags.io/RE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-RO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/RO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-RO::before {
  background-image: url(https://www.countryflags.io/RO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-RU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/RU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-RU::before {
  background-image: url(https://www.countryflags.io/RU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-RW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/RW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-RW::before {
  background-image: url(https://www.countryflags.io/RW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-BL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/BL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-BL::before {
  background-image: url(https://www.countryflags.io/BL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SH::before {
  background-image: url(https://www.countryflags.io/SH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-KN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/KN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-KN::before {
  background-image: url(https://www.countryflags.io/KN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LC::before {
  background-image: url(https://www.countryflags.io/LC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-MF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/MF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-MF::before {
  background-image: url(https://www.countryflags.io/MF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-PM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/PM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-PM::before {
  background-image: url(https://www.countryflags.io/PM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VC::before {
  background-image: url(https://www.countryflags.io/VC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-WS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/WS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-WS::before {
  background-image: url(https://www.countryflags.io/WS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SM::before {
  background-image: url(https://www.countryflags.io/SM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ST::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ST/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ST::before {
  background-image: url(https://www.countryflags.io/ST/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SA::before {
  background-image: url(https://www.countryflags.io/SA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SN::before {
  background-image: url(https://www.countryflags.io/SN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-RS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/RS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-RS::before {
  background-image: url(https://www.countryflags.io/RS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SC::before {
  background-image: url(https://www.countryflags.io/SC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SL::before {
  background-image: url(https://www.countryflags.io/SL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SG::before {
  background-image: url(https://www.countryflags.io/SG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SK::before {
  background-image: url(https://www.countryflags.io/SK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SI::before {
  background-image: url(https://www.countryflags.io/SI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SB::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SB/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SB::before {
  background-image: url(https://www.countryflags.io/SB/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SO::before {
  background-image: url(https://www.countryflags.io/SO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ZA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ZA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ZA::before {
  background-image: url(https://www.countryflags.io/ZA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GS::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GS/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GS::before {
  background-image: url(https://www.countryflags.io/GS/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ES::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ES/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ES::before {
  background-image: url(https://www.countryflags.io/ES/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-LK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/LK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-LK::before {
  background-image: url(https://www.countryflags.io/LK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SD::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SD/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SD::before {
  background-image: url(https://www.countryflags.io/SD/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SR::before {
  background-image: url(https://www.countryflags.io/SR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SJ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SJ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SJ::before {
  background-image: url(https://www.countryflags.io/SJ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SZ::before {
  background-image: url(https://www.countryflags.io/SZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SE::before {
  background-image: url(https://www.countryflags.io/SE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-CH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/CH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-CH::before {
  background-image: url(https://www.countryflags.io/CH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-SY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/SY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-SY::before {
  background-image: url(https://www.countryflags.io/SY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TW::before {
  background-image: url(https://www.countryflags.io/TW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TJ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TJ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TJ::before {
  background-image: url(https://www.countryflags.io/TJ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TZ::before {
  background-image: url(https://www.countryflags.io/TZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TH::before {
  background-image: url(https://www.countryflags.io/TH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TL::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TL/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TL::before {
  background-image: url(https://www.countryflags.io/TL/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TG::before {
  background-image: url(https://www.countryflags.io/TG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TK::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TK/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TK::before {
  background-image: url(https://www.countryflags.io/TK/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TO::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TO/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TO::before {
  background-image: url(https://www.countryflags.io/TO/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TT::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TT/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TT::before {
  background-image: url(https://www.countryflags.io/TT/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TN::before {
  background-image: url(https://www.countryflags.io/TN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TR::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TR/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TR::before {
  background-image: url(https://www.countryflags.io/TR/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TM::before {
  background-image: url(https://www.countryflags.io/TM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TC::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TC/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TC::before {
  background-image: url(https://www.countryflags.io/TC/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-TV::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/TV/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-TV::before {
  background-image: url(https://www.countryflags.io/TV/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-UG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/UG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-UG::before {
  background-image: url(https://www.countryflags.io/UG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-UA::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/UA/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-UA::before {
  background-image: url(https://www.countryflags.io/UA/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-AE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/AE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-AE::before {
  background-image: url(https://www.countryflags.io/AE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-GB::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/GB/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-GB::before {
  background-image: url(https://www.countryflags.io/GB/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-US::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/US/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-US::before {
  background-image: url(https://www.countryflags.io/US/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-UM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/UM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-UM::before {
  background-image: url(https://www.countryflags.io/UM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-UY::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/UY/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-UY::before {
  background-image: url(https://www.countryflags.io/UY/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-UZ::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/UZ/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-UZ::before {
  background-image: url(https://www.countryflags.io/UZ/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VU::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VU/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VU::before {
  background-image: url(https://www.countryflags.io/VU/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VE::before {
  background-image: url(https://www.countryflags.io/VE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VN::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VN/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VN::before {
  background-image: url(https://www.countryflags.io/VN/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VG::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VG/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VG::before {
  background-image: url(https://www.countryflags.io/VG/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-VI::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/VI/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-VI::before {
  background-image: url(https://www.countryflags.io/VI/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-WF::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/WF/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-WF::before {
  background-image: url(https://www.countryflags.io/WF/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-EH::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/EH/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-EH::before {
  background-image: url(https://www.countryflags.io/EH/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-YE::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/YE/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-YE::before {
  background-image: url(https://www.countryflags.io/YE/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ZM::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ZM/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ZM::before {
  background-image: url(https://www.countryflags.io/ZM/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}

.start-ZW::after {
  position: absolute;
  background-image: url("https://www.countryflags.io/ZW/shiny/24.png");
  width: 24px;
  height: 24px;
  content: " ";
}

.end-ZW::before {
  background-image: url(https://www.countryflags.io/ZW/shiny/24.png);
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  content: " ";
}