.cell {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.date {
    font-size: 10px;
    color: rgba(155, 155, 155, 0.774);
}

.button {
    background-color: rgb(18, 152, 101) !important;
    border-radius: 6px !important;
    padding: 2px 5px !important;
    color: white !important;
    border: 1px solid rgb(19, 146, 19) !important;
    font-size: 10px !important;
}

.carDetails {
    padding-bottom: 20px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.additionalCols {
    display: flex !important;
    justify-content: space-between ;
    align-items: center ;
    border-bottom: 1px solid black;
    padding-left: 10px;
}

.additionalColsAdd {
    display: flex !important;
    justify-content: space-between ;
    align-items: center ;
}


.additionalColsAddBtn{
    color: green
}

.allFiles {
    display:flex !important;
    justify-content: space-between;
    align-items: center !important;
    border-bottom: 1px solid black;
}

.showAllFiles {
    cursor: pointer;
}

.editDates{
    min-height: 41vh !important;
}

.login {
    height: 20vh !important;
    display: flex;
    gap: 30px;
    flex-direction: column;
}

.headCell {
    padding-left: 20px !important;
}

.driversCols {
    width: 150px !important;
}

.driversFullName{
    padding-left: 20px !important;
    width: 200px !important;
}

.allFilesButtonContainer{
    display: flex;
    gap: 5px;
}

.downloadBtn{
    background-color: rgb(18, 152, 101) !important;
    margin: 10px 0px !important;
}

.currentFileContainer{
    display: flex;
    gap: 20px;
    align-items: center;
}

.download{
    cursor: pointer;
}

@font-face {
    font-family: 'password';
    font-style: normal;
    font-weight: 400;
    src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
  }
  
  input.key {
    font-family: 'password' !important;
    border: 1px solid var(--input-bor);
    border-radius: 5px;
    padding: 11px;
    font-size: var(--fontSize);
    color: var(--text-col);
    background-color: var(--input-bg);
    box-sizing: border-box;
    transition: all .3s ease;
    height: 38px;
    width: 100%; 
  }

  .hiddenInput {
    height: 0px !important;
    width: 0px !important;
    position: relative;
    right: 1000000px;
  }

  .inp {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    gap: 10px;
    color: black !important;
    font-weight: 600;
  }
  
  input:focus::placeholder {
    color: transparent;
  }
  
  .inp.start {
    justify-content: flex-start;
  }
  
  .inp h6 {
    font-size: 10px;
    color: var(--sidebar-bg-col);
    font-weight: 500;
  }
  
  .inp label {
    font-size:11px;
    font-weight: 600;
    color: var(--text-col);
    width: 30%;
    text-align: start;
  }
  
  .inp label span {
    font-size: 11px;
    font-weight: 500;
    color: var(--text-col);
  }
  
  .inp label span.error {
    color: var(--err-col);
  }
  
  .inputText input{
    font-family: Poppins !important;
  }
  
  .select > div {
    border: 1px solid var(--input-bor);
    border-radius: 5px;
    font-size: var(--fontSize);
    color: var(--text-col);
    background-color: var(--input-bg);
    /* max-height: 35px; */
  }
  
  div.selectError {
    max-height: 38px;
  }
  
  .selectError > div {
    border: 1px solid var(--err-col);
    border-radius: 5px;
    font-size: var(--fontSize);
    color: var(--text-col);
    background-color: var(--input-bg);
    max-height: 35px;
  }
  
  .selectError{
    width: 100% !important;
  }
  
  .select{
    width: 100% !important;
  }
  
  .date{
    display: flex;
    border-radius: 5px;
    color: var(--text-col);
    background-color: var(--input-bg);
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .invoisPicker{
    height: 38px !important;
  }
  
  .invoisPicker label{
    margin: 0 !important;
    text-align: left;
    padding: 0 10px
  }
  
  .datePicker {
    display: flex;
    justify-content:right;
    height: 100%;
    align-items: center;
    gap: 5px;
  }
  
  .datePicker.start {
    justify-content: flex-start;
  }
  
  .datePicker h6 {
    font-size: 10px;
    color: var(--sidebar-bg-col);
    font-weight: 500;
  }
  
  .datePicker label {
    font-size:11px;
    font-weight: 600;
    color: var(--text-col);
    width: 80%;
    /* margin: 10px; */
  }
  
  .datePicker label span {
    font-size: 11px;
    font-weight: 500;
    color: var(--text-col);
  }
  
  .datePicker label span.error {
    color: var(--err-col);
  }

  .dataPicker{
    height: 38px !important;
  }
  .dataPicker label{
    text-align: center;
  }

  .historyCell{
    width: 20% !important
  }

  div.btnDisabled,
  button.btnDisabled {
    margin: 0 3px 1px;
    color: var(--white);
    text-transform: none;
    font-size: 10px;
    font-weight: 500;
    height: 25px;
    transition: all 0.3s ease

  }

  div.btnDisabled {
    background-color: rgb(149, 157, 163);
    color: var(--sidebar-bg-col) !important;
    border: 0;
  }

  div.btnDisabled span {
    font-size: 10px !important;
    font-weight: 500;
  }

  div.btnDisabled:hover {
    background-color: rgb(149, 157, 163) !important;
    color: var(--sidebar-bg-col) !important;
    border: 0 !important;
  }

  .statusHistoryBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px
  }