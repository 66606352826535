.tableBottom {
	width: 100%;
	height: 40px;
	background-color: var(--main-col-op);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
	box-sizing: border-box;
}

.tableBottom button {
	color:var(--white);
	border: 1px solid var(--white);
	margin: 0 5px
}