.popupHeader{
    background: var(--main-col-op);
    padding: 24px
}
.popupHeader label{
    color: #fff;
}
.seperator {
    width: 100%;
    height: 1px;
    display: block;
    background: #cdcdcd;
    margin: 15px 0;
}