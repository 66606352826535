.check {
  margin: 0 5px 0 0;
}

.label {
  position: relative;
  top: -2px;
  font-size: 11px;
}

.title {
  margin-bottom: 15px;
}
