.subtitle {
	width: 100%;
	height: 40px;
	background-color: var(--topbar-col);
	display: flex;
	align-items: center;
	padding-left: 20px;
	font-weight: 600;
	font-size: 16px;
	position: relative;
	gap: 50px;
}
.value{
	font-weight: bold !important;
}
.labelValueRow {
	display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
    height: 100%;
    align-items: center;
    font-size: 12px;
}
.right {
	position: absolute;
	right: 20px;
	top: 1px;
	display:flex;
	align-items: baseline;
}

.right>*{
	margin-left:10px;
}