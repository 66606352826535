.fleet-costs {

    &__table-label {
        display: flex;
        cursor: pointer;
        gap: 2px;
        justify-content: center;
        text-align: center;
        &--car {
            justify-content: center;
            text-align: center;
        }
    }

    &__table-cell {
        display: flex;
        gap: 2px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &--icon-container {
            width: 20%;
            display: flex;
            gap: 2px;
        }
        &--value-container {
            width: 80%
        }
        &--vertical-border {
            border-right: 1px solid black
        }
    }

    &__table-row {

        &--last{
            border-bottom: 1px solid black;
        }
    }

    &__delete-data-modal{

        &--title {
            display: flex;
        }

        &--content {
            display: flex;
        }
    };

    &__table-icon{
        opacity: 0.5 !important;
        height: 15px !important;
        width: 15px !important;
        &:hover {
            opacity:1 !important;
        }
    };

    &__delete-data-modal-cell {

        &--big {
            width: 60%
        }
        &--mid {
            width: 30%
        }
        &--small {
            width: 10%
        }
    }
}

.fleet-costs-report {

    &__table-row {

        &--last{
            border-bottom: 3px solid black !important;
        }
    }

}

.fleet-costs-reports-confirmed {

    &__table-row{
        display:flex;
        justify-content: space-around;
    }

    &__icon-container {
        display: flex !important;
        gap: 2px;
        justify-content: flex-end;
        padding-right: 20px;
        min-height: 75px;
        align-items: center;
    }

    &__table-cell {
        min-height: 75px;
        &--first{
            padding-left: 20px !important;
        }
    }
}