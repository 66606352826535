.mainView {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: var(--lgray);
}

.view {
  width: calc(100% - 200px);
  margin-left: 200px;
  background-color: var(--bg);
  min-width: 1150px;
  max-width: 2400px;
}
