.end_time {
  background: var(--cor-col);
  color: var(--white);
  padding: 10px;
  font-size: 10px;
  width: 100%;
  border-radius: 4px;
  text-align: left;
  display: flex;
  gap: 5px;
  align-items: center;
  letter-spacing: 0;
}
.end_time > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.end_time button {
  background-color: var(--main-col-op);
  color: var(--white);
  margin-bottom: 7px;
}
.end_time button:hover {
  background-color: var(--main-col-op);
}
.end_time button span {
  padding: 0 !important;
  font-size: 10px;
  text-transform: capitalize;
}
.end_time a {
  text-decoration: none;
  color: var(--white);
}
.end_time a ~ span {
  font-weight: 300;
  padding: 0 3px 0 1px;
}
.dataEnd {
  background-color: var(--cor-col);
  width: 100%;
  text-align: left;
  border-radius: 4px;
  padding: 3px 0;
  margin-top: 3px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.end_time a:hover {
  text-decoration: underline;
}
.value {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  margin-top: 2px;
}
.baner {
  z-index: 99;
  height: 230px;
}
.baner img {
  border-radius: 0px;
  cursor: pointer;
}
.close {
  position: absolute !important;
  top: 11px;
  right: 10px;
  padding: 0 !important;
  min-width: unset !important;
}
