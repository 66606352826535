body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%
}

#root {
  height: 100%
}

div.MuiListItemIcon-root {
  min-width: 20px;
}

textarea {
  resize: none;
  font-family: Poppins;
  font-size: var(--fontSize);
  color: var(--text-col);
  border-color: var(--text-col);
  border-radius: 6px;
  padding: 5px;
  width: 100%
}

.BTNbar .react-datepicker__tab-loop {
  position: absolute;
}

.BTNbar .react-datepicker__tab-loop .react-datepicker-popper {
  min-width: 240px;
}

.BTNbar .react-datepicker__tab-loop .react-datepicker-popper>div>div {
  font-size: 11px;
}

.react-datepicker__tab-loop .react-datepicker-popper>div>div button {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  top: 4px;
}

.BTNbar .react-datepicker__year-read-view--down-arrow,
.BTNbar .react-datepicker__month-read-view--down-arrow,
.BTNbar .react-datepicker__month-year-read-view--down-arrow,
.BTNbar .react-datepicker__navigation-icon::before {
  border-color: var(--input-bor);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 10px;
  width: 9px;
}


.betaTopBar {
  background: var(--main-col);
  position: fixed;
  z-index: 99;
  color: var(--white);
  font-weight: 700;
  font-size: 10px;
  left: 154px;
  top: 10px;
  padding: 1px 6px;
  border-radius: 3px;
}

.betaTopBarLogOut {
  background: var(--main-col);
  position: absolute;
  z-index: 99;
  color: var(--white);
  font-weight: 700;
  font-size: 14px;
  left: 370px;
  right: 0;
  top: 0;
  bottom: 492px;
  padding: 1px 6px;
  border-radius: 4px;
  margin: auto;
  width: 65px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

}

@media (max-height: 635px) {
  .betaTopBarLogOut {
    top: 10px;
    left: 10px;
    right: auto;
    bottom: auto;
  }
}


/* .betaTopBar {
  background: red;
  width: 100%;
  height: 20px;
  z-index: 999;
  position: relative;
  text-align: center;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  box-shadow: 0 2px 2px 0px hsl(0deg 0% 0% / 17%);
}
.betaTopBar + div > div {
  height: calc(100vh - 20px);
}
.betaTopBar + div > div #slide-box {
  height: calc(100vh - 140px);
}
.mainSet {
  box-sizing: border-box;
}
.betaTopBar + div > div .MuiTabs-root {
  min-height: 38px;
  height: 38px;
}
.betaTopBar + div > div button span {
  padding: 0 !important;
}
.betaTopBar + div > div button span .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 0;
}
.betaTopBar + div > div .top-bar {
  height: 30px;
}
.betaTopBar + div > div .top-bar > div {
  display: flex;
  align-items: center;
}
.betaTopBar + div > div .top-bar > a svg{
  margin: 7px 25px;
}
.betaTopBar + div > div .top-bar > div > * {
  display: flex;
  align-items: center;
  height: 30px;
}
.betaTopBar + div > div .top-bar > div > * svg {
  margin: 7px 25px;
} */


/* nowy datapicker */

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 3px;
  padding: 4px 0;
}